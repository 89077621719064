import CallIcon from "@mui/icons-material/Call";
import {
  Avatar,
  Box,
  Grid,
  styled,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import UserNotRegisteredImage from "../../../../../assets/images/user-not-registered.png";
import CustomButton from "../../../../../components/button/custom-button.component";
import FormFieldTextWithIcon from "../../../../../components/forms/form-field-text-with-icon.component";
import FormSubmitButton from "../../../../../components/forms/form-submit-button.component";
import Form from "../../../../../components/forms/form.component";
import PaymentMethodCheckbox from "../../../../../components/forms/payment-method-checkbox.component";
import routes from "../../../../../components/navigation/routes";
import Loading from "../../../../../components/notification/backdrop-loading.component";
import DialogBox from "../../../../../components/notification/dialog-box.component";
import InsufficientCreditModal from "../../../../../components/notification/insufficient-credit-modal.component";
import { SnackbarContext } from "../../../../../components/notification/snackbar.context";
import HorizontalDivider from "../../../../../components/utils/horizontal-divider.component";
import PaddedView from "../../../../../components/utils/padded-view.component";
import Spacer from "../../../../../components/utils/spacer.component";
import {
  createFitnessClassSessionBooking,
  fitnessClassSessionBookingSelector,
  previewFitnessClassSessionBookingSummary,
} from "../../../../../services/fitness_class/session/booking/fitness-class-session-booking-slice.service";
import {
  fitnessClassSessionSelector,
  getFitnessClassSession,
} from "../../../../../services/fitness_class/session/fitness-class-session-slice.services";
import {
  bookSessionViaPackageCode,
  checkReceivingUserAllowToPurchaseSession,
} from "../../../../../services/package/session/booking/package-fitness-session-booking-slice.service";
import { getProfileDetail } from "../../../../../services/profile/profile-slice.service";
import { userSearchByPhoneNo } from "../../../../../services/social/social-slice.service";
import PreviewSummaryLoader from "../loader/preview-summary-loader.component";

const TitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size22,
  fontWeight: "bold",
}));

const SectionTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size18,
  fontWeight: "bold",
}));

const BodyTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size16,
}));

const SectionContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.colors.bg.primary,
  borderRadius: `${theme.shape.borderRadius[2]}px`,
}));

const GiftSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: theme.palette.colors.text.white,
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.colors.button.switch,
  },
}));

function FitnessClassPreviewSummaryScreen() {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { previewFitnessClassSessionBookingSummaryObj } = useSelector(
    fitnessClassSessionBookingSelector,
  );
  const { getFitnessClassSessionObj } = useSelector(fitnessClassSessionSelector);
  const createSnackBar = useContext(SnackbarContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const formRef = useRef();
  const promoCodeFormRef = useRef();
  const giftFormRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [purchasedAsGift, setPurchasedAsGift] = useState(
    location.state.onlyAllowPurchasedAsGift || false,
  );
  const [showUserNotVerifiedModal, setShowUserNotVerifiedModal] = useState(false);
  const [showUserNotFoundModal, setShowUserNotFoundModal] = useState(false);
  const [purchaseGiftVerifiedSuccess, setPurchaseGiftVerifiedSuccess] = useState(false);
  const [dataObj, setDataObj] = useState(null);
  const [showInsufficientCreditModal, setShowInsufficientCreditModal] = useState(false);
  const [redeemSuccess, setRedeemSuccess] = useState(false);
  const [isRedeemingPromo, setIsRedeemingPromo] = useState(false);

  const validationSchema = Yup.object().shape({
    paymentMethod: Yup.string().required().label("Payment Method"),
    fitnessClassSessionId: Yup.number().required().label("Session Id"),
    sessionId: Yup.number().required().label("Session Id"),
    packageCode: Yup.string().nullable().label("Package Code"),
    purchasedAsGift: Yup.bool().label("Purchase As Gift"),
    userPhoneNo: Yup.string()
      .when("purchasedAsGift", {
        is: true,
        then: () => Yup.string().required().label("Phone No"),
      })
      .label("User Phone No"),
  });

  const giftValidationSchema = Yup.object().shape({
    sessionId: Yup.number().required().label("Session Id"),
    purchasedAsGift: Yup.bool().label("Purchase As Gift"),
    userPhoneNo: Yup.string()
      .when("purchasedAsGift", {
        is: true,
        then: () => Yup.string().required(),
      })
      .label("User Phone No"),
  });

  const previewFitness = (values, promoRedeem) => {
    if (promoRedeem) {
      setIsRedeemingPromo(true);
      setIsLoading(true);
    }

    dispatch(previewFitnessClassSessionBookingSummary(values)).then(({ meta, error }) => {
      setIsLoading(false);

      if (meta.requestStatus === "fulfilled") {
        formRef.current.setFieldValue("promoCode", values.promoCode);
        setRedeemSuccess(true);
      }

      if (meta.requestStatus === "rejected") {
        formRef.current.setFieldValue("promoCode", "");
        promoCodeFormRef.current.setFieldValue("promoCode", "");
        setRedeemSuccess(false);
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const clearPromoForm = () => {
    formRef.current.setFieldValue("promoCode", "");
    promoCodeFormRef.current.setFieldValue("promoCode", "");
    previewFitness({ ...formRef.current.values, promoCode: "" }, true);
  };

  const handleFormSubmission = (values) => {
    setIsLoading(true);
    if (location.state.packageCode) {
      dispatch(bookSessionViaPackageCode(values)).then(({ meta, error, payload }) => {
        setIsLoading(false);

        if (meta.requestStatus === "fulfilled") {
          navigate(routes.FITNESSCLASSSESSIONBOOKINGPAYMENTSUCCESS, {
            state: { ...payload.data },
          });
          createSnackBar({
            message: payload.message,
            type: "success",
            open: true,
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      });
    } else {
      dispatch(createFitnessClassSessionBooking(values)).then(({ meta, error, payload }) => {
        setIsLoading(false);

        if (meta.requestStatus === "fulfilled") {
          if (
            values.paymentMethod === "credits" ||
            payload.data.paymentTransaction.status === "paid"
          ) {
            dispatch(getProfileDetail());
            navigate(routes.FITNESSCLASSSESSIONBOOKINGPAYMENTSUCCESS, {
              state: { ...payload.data },
            });
            createSnackBar({
              message: payload.message,
              type: "success",
              open: true,
            });
          } else {
            window.open(payload.data.bill.remoteUrl, "_self");
          }
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      });
    }
  };

  const handleSubmit = (values) => {
    if (
      values.paymentMethod === "credits" &&
      !values.packageCode &&
      !previewFitnessClassSessionBookingSummaryObj.data.creditPurchasable
    ) {
      setShowInsufficientCreditModal(true);
      return;
    }

    handleFormSubmission(values);
  };

  const checkReceivingUserAllowToBook = (values) => {
    dispatch(
      checkReceivingUserAllowToPurchaseSession({
        ...values,
        userPhoneNo: values.userPhoneNo.replace("+", ""),
      }),
    ).then(({ meta, error, payload }) => {
      setIsLoading(false);

      if (meta.requestStatus === "fulfilled") {
        formRef.current.setFieldValue("userPhoneNo", values.userPhoneNo.replace("+", ""));
        setPurchaseGiftVerifiedSuccess(true);
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const purchaseAsGift = (values) => {
    setPurchaseGiftVerifiedSuccess(false);
    setIsLoading(true);
    dispatch(userSearchByPhoneNo({ phoneNo: values.userPhoneNo.replace("+", "") })).then(
      ({ meta, error, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          if (payload.data.verified) {
            checkReceivingUserAllowToBook(values);
          } else {
            setIsLoading(false);
            setShowUserNotVerifiedModal(true);
            giftFormRef.current.setFieldValue("userPhoneNo", "");
            formRef.current.setFieldValue("userPhoneNo", "");
          }
        }
        if (meta.requestStatus === "rejected") {
          giftFormRef.current.setFieldValue("userPhoneNo", "");
          formRef.current.setFieldValue("userPhoneNo", "");
          setIsLoading(false);
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
          if (error.message.includes("User not found")) {
            setShowUserNotFoundModal(true);
          }
        }
      },
    );
  };

  const handleSwitchChange = (event) => {
    if (event.target.checked) {
      setPurchasedAsGift(true);
      formRef.current.setFieldValue(`purchasedAsGift`, true);
      giftFormRef.current.setFieldValue(`purchasedAsGift`, true);
    } else {
      giftFormRef.current.setFieldValue("userPhoneNo", "");
      formRef.current.setFieldValue("userPhoneNo", "");
      setPurchasedAsGift(false);
      formRef.current.setFieldValue(`purchasedAsGift`, false);
      giftFormRef.current.setFieldValue(`purchasedAsGift`, false);
    }
  };

  useEffect(() => {
    if (location.state.onlyAllowPurchasedAsGift) {
      dispatch(
        getFitnessClassSession({
          sessionId: location.state.sessionId,
        }),
      ).then(({ meta, error, payload }) => {
        setIsLoading(false);

        if (meta.requestStatus === "fulfilled") {
          setDataObj(payload.data);
        }

        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      });
    } else {
      dispatch(
        previewFitnessClassSessionBookingSummary({
          fitnessClassSessionId: location.state.sessionId,
        }),
      ).then(({ meta, error, payload }) => {
        setIsLoading(false);

        if (meta.requestStatus === "fulfilled") {
          setDataObj(payload.data.session);
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      });
    }
  }, []);

  return (
    <Box>
      {previewFitnessClassSessionBookingSummaryObj.status === "succeeded" && (
        <InsufficientCreditModal
          isShow={showInsufficientCreditModal}
          setIsShowAlert={setShowInsufficientCreditModal}
        />
      )}
      {location.state.packageCode && (
        <>
          <DialogBox
            title="Booking is non-refundable."
            isShow={showConfirmModal}
            setIsShowAlert={setShowConfirmModal}
            message={
              <Grid item>
                <Typography sx={{ textAlign: "center" }}>
                  By continuing, you agree that this booking cannot be cancelled or rescheduled.
                </Typography>
              </Grid>
            }
            isConfirmHandle={() => {
              setShowConfirmModal(false);
              formRef.current.handleSubmit();
            }}
            buttonText="Ok"
          />
          <DialogBox
            title="User not verified"
            isShow={showUserNotVerifiedModal}
            setIsShowAlert={setShowUserNotVerifiedModal}
            message={
              <Grid item>
                <Grid container display="flex" justifyContent="center">
                  <img
                    width={250}
                    src={UserNotRegisteredImage}
                    alt="no-registered"
                    style={{ objectFit: "contain" }}
                  />
                </Grid>
                <Spacer size="m" />
                <Typography sx={{ textAlign: "center" }}>
                  The selected contact has not been verified with Katch.
                </Typography>
                <Typography sx={{ textAlign: "center" }}>Please select another user.</Typography>
              </Grid>
            }
            isConfirmHandle={() => {
              setShowUserNotVerifiedModal(false);
            }}
            buttonText="Ok"
          />
          <DialogBox
            title="User not registered"
            isShow={showUserNotFoundModal}
            setIsShowAlert={setShowUserNotFoundModal}
            message={
              <Grid item>
                <Grid container display="flex" justifyContent="center">
                  <img
                    width={250}
                    src={UserNotRegisteredImage}
                    alt="no-registered"
                    style={{ objectFit: "contain" }}
                  />
                </Grid>
                <Spacer size="m" />
                <Typography sx={{ textAlign: "center" }}>
                  The selected contact is not registered with Katch. Send an invite for registration
                  now!
                </Typography>
              </Grid>
            }
            isConfirmHandle={() => {
              setShowUserNotFoundModal(false);
              navigate(routes.REFERRALS);
            }}
            buttonText="Send Invite Now"
          />
        </>
      )}

      <Loading isLoading={isLoading} />
      <Spacer size="l" />
      <PaddedView multiples={2}>
        <TitleTypography>Booking Details</TitleTypography>
        <Spacer size="l" />
        {(dataObj &&
          (getFitnessClassSessionObj.status === "succeeded" ||
            previewFitnessClassSessionBookingSummaryObj.status === "succeeded")) ||
        isRedeemingPromo ? (
          <Form
            innerRef={formRef}
            initialValues={{
              paymentMethod: "credits",
              fitnessClassSessionId: location.state.sessionId,
              sessionId: location.state.sessionId,
              packageCode: location.state.packageCode ? location.state.packageCode : null,
              userPhoneNo: "",
              purchasedAsGift: location.state.onlyAllowPurchasedAsGift || false,
              promoCode: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Grid container columnSpacing={2} rowSpacing={2}>
              {dataObj && (
                <>
                  <Grid container item xs={isMobile ? 12 : 7} flexDirection="column" rowSpacing={2}>
                    <Grid item>
                      <SectionContainer item>
                        <PaddedView multiples={isMobile ? 3 : 4}>
                          {location.state.packageCode && (
                            <>
                              <Grid item display="flex" alignItems="center">
                                <Typography
                                  fontSize={theme.fonts.fontSizes.size14}
                                  sx={{
                                    border: "1px solid",
                                    borderColor: theme.palette.colors.ui.error,
                                    color: theme.palette.colors.ui.error,
                                    paddingX: "10px",
                                  }}
                                >
                                  NON-REFUNDABLE
                                </Typography>
                              </Grid>
                              <Spacer size="m" />
                            </>
                          )}
                          <Typography fontSize={theme.fonts.fontSizes.size14}>
                            {dataObj.class.category.label}
                          </Typography>
                          <Spacer />
                          <Grid item key={uuidv4()}>
                            <Grid container justifyContent="space-between" alignItems="center">
                              <TitleTypography>{dataObj.class.name}</TitleTypography>
                              <BodyTypography color="primary" fontWeight="bold">
                                RM {dataObj.price}
                              </BodyTypography>
                            </Grid>
                            <Spacer size="m" />
                            <Grid item>
                              <BodyTypography>
                                {dataObj.startTime} ({dataObj.durationMins}
                                mins) | {dataObj.date}
                              </BodyTypography>
                              <BodyTypography>{dataObj.business.name}</BodyTypography>
                            </Grid>
                            <Spacer size="m" />
                            <Grid item container rowSpacing={1}>
                              <Grid item xs={12}>
                                <Typography fontWeight="bold">Staffs</Typography>
                              </Grid>
                              <Grid container item columnSpacing={2}>
                                {dataObj.staffs.map((item) => (
                                  <Grid item key={item.id}>
                                    <Grid container alignItems="center">
                                      <Avatar
                                        src={item.imagePath}
                                        sx={{
                                          width: "40px",
                                          height: "40px",
                                        }}
                                      />
                                      <Spacer position="left" />
                                      <Typography>{item.name}</Typography>
                                    </Grid>
                                  </Grid>
                                ))}
                              </Grid>
                            </Grid>
                            <Spacer size="m" />
                          </Grid>
                        </PaddedView>
                      </SectionContainer>
                    </Grid>

                    {!location.state.packageCode && (
                      <Grid item>
                        <SectionContainer item>
                          <PaddedView multiples={isMobile ? 3 : 4}>
                            <SectionTypography>Promos & Vouchers</SectionTypography>
                            <Spacer size="m" />

                            <Form
                              innerRef={promoCodeFormRef}
                              initialValues={{
                                promoCode: "",
                              }}
                              validationSchema={Yup.object().shape({
                                promoCode: Yup.string().required().label("Promo Code"),
                              })}
                              onSubmit={(values) => {
                                previewFitness(
                                  {
                                    ...formRef.current.values,
                                    ...values,
                                  },
                                  true,
                                );
                              }}
                            >
                              <Grid container columnSpacing={2}>
                                <Grid item xs={8}>
                                  <FormFieldTextWithIcon
                                    name="promoCode"
                                    placeholder="Promo Code"
                                    toUpper={true}
                                    clearPromoForm={clearPromoForm}
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <FormSubmitButton width="100%" borderRadius={3}>
                                    <Typography>Redeem</Typography>
                                  </FormSubmitButton>
                                </Grid>
                              </Grid>
                            </Form>
                          </PaddedView>
                        </SectionContainer>
                      </Grid>
                    )}

                    <Grid item>
                      <SectionContainer item>
                        {location.state.packageCode ? (
                          <PaddedView multiples={isMobile ? 3 : 4}>
                            <SectionTypography>Package Code</SectionTypography>
                            <Spacer size="m" />
                            <Grid item display="flex">
                              <Typography
                                sx={{
                                  border: "1px solid",
                                  borderRadius: `${theme.shape.borderRadius[1]}px`,
                                  padding: "10px",
                                  paddingX: "30px",
                                  fontWeight: "bold",
                                }}
                              >
                                {location.state.packageCode}
                              </Typography>
                            </Grid>
                          </PaddedView>
                        ) : (
                          <PaddedView multiples={isMobile ? 3 : 4}>
                            <SectionTypography>Payment Method</SectionTypography>
                            <Spacer size="m" />
                            <PaymentMethodCheckbox />
                          </PaddedView>
                        )}
                      </SectionContainer>
                    </Grid>
                    {location.state.packageCode && (
                      <Grid item>
                        <SectionContainer item>
                          <PaddedView multiples={isMobile ? 3 : 4}>
                            <Form
                              innerRef={giftFormRef}
                              initialValues={{
                                sessionId: location.state.sessionId,
                                userPhoneNo: "",
                                purchasedAsGift: location.state.onlyAllowPurchasedAsGift || false,
                              }}
                              validationSchema={giftValidationSchema}
                              onSubmit={purchaseAsGift}
                            >
                              <Grid item container justifyContent="space-between">
                                <SectionTypography>Purchase as a gift</SectionTypography>
                                <GiftSwitch
                                  onChange={handleSwitchChange}
                                  checked={location.state.onlyAllowPurchasedAsGift}
                                  disabled={location.state.onlyAllowPurchasedAsGift}
                                />
                              </Grid>
                              <Spacer size="m" />
                              {purchasedAsGift && (
                                <Grid item container columnSpacing={1}>
                                  <Grid item xs={isMobile ? 8 : 7}>
                                    <FormFieldTextWithIcon
                                      name="userPhoneNo"
                                      placeholder="Phone number"
                                      showIcon={true}
                                      icon={<CallIcon color="primary" />}
                                    />
                                  </Grid>
                                  <Grid item textAlign="center" xs={isMobile ? 4 : 5}>
                                    <FormSubmitButton isLoading={isLoading} borderRadius={3}>
                                      <Typography>Search</Typography>
                                    </FormSubmitButton>
                                  </Grid>
                                </Grid>
                              )}
                            </Form>
                          </PaddedView>
                        </SectionContainer>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={isMobile ? 12 : 5}>
                    <SectionContainer item>
                      <PaddedView multiples={isMobile ? 3 : 4}>
                        <TitleTypography>Payment Summary</TitleTypography>
                        <Spacer size="m" />
                        <Grid
                          item
                          display="grid"
                          flexDirection="column"
                          alignContent="space-between"
                          minHeight="220px"
                        >
                          <Grid
                            container
                            item
                            dispaly="flex"
                            justifyContent="space-between"
                            rowSpacing={1}
                          >
                            <Grid item xs={6}>
                              <BodyTypography>Subtotal</BodyTypography>
                            </Grid>
                            <Grid item xs={6} textAlign="right">
                              <BodyTypography>
                                RM{" "}
                                {location.state.onlyAllowPurchasedAsGift
                                  ? dataObj.price
                                  : previewFitnessClassSessionBookingSummaryObj.data.paymentSummary
                                      .subtotal}
                              </BodyTypography>
                            </Grid>
                            {location.state.packageCode && (
                              <>
                                <Grid item xs={6}>
                                  <BodyTypography>Discount</BodyTypography>
                                </Grid>
                                <Grid item xs={6} textAlign="right">
                                  <BodyTypography>
                                    -RM{" "}
                                    {location.state.onlyAllowPurchasedAsGift
                                      ? dataObj.price
                                      : previewFitnessClassSessionBookingSummaryObj.data
                                          .paymentSummary.subtotal}
                                  </BodyTypography>
                                </Grid>
                              </>
                            )}
                            {previewFitnessClassSessionBookingSummaryObj.data?.paymentSummary
                              .voucherDiscount !== "0.00" &&
                              redeemSuccess && (
                                <>
                                  <Grid item xs={6}>
                                    <BodyTypography>Voucher Discount</BodyTypography>
                                  </Grid>
                                  <Grid item xs={6} textAlign="right">
                                    <BodyTypography>
                                      -RM{" "}
                                      {
                                        previewFitnessClassSessionBookingSummaryObj.data
                                          ?.paymentSummary.voucherDiscount
                                      }
                                    </BodyTypography>
                                  </Grid>
                                </>
                              )}
                          </Grid>
                          <Grid item>
                            <HorizontalDivider spacerSize="l" />
                            <Grid
                              container
                              item
                              dispaly="flex"
                              justifyContent="space-between"
                              rowSpacing={1}
                            >
                              <SectionTypography>Total Price</SectionTypography>
                              <SectionTypography>
                                RM{" "}
                                {location.state.packageCode
                                  ? "0.00"
                                  : previewFitnessClassSessionBookingSummaryObj.data.paymentSummary
                                      .totalPrice}
                              </SectionTypography>
                            </Grid>
                            <Spacer size="xl" />
                            <Grid item>
                              <CustomButton
                                disabled={purchasedAsGift ? !purchaseGiftVerifiedSuccess : false}
                                fontSize={theme.fonts.fontSizes.size16}
                                onClick={() => {
                                  if (location.state.packageCode) {
                                    setShowConfirmModal(true);
                                  } else {
                                    formRef.current.handleSubmit();
                                  }
                                }}
                              >
                                Confirm
                              </CustomButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      </PaddedView>
                    </SectionContainer>
                  </Grid>
                </>
              )}
            </Grid>
          </Form>
        ) : (
          <PreviewSummaryLoader />
        )}
      </PaddedView>
    </Box>
  );
}

export default FitnessClassPreviewSummaryScreen;
