import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import ReactCodeInput from "react-verification-code-input";
import * as Yup from "yup";
import KatchLogo from "../../../assets/images/katch_logo";
import ResendButton from "../../../components/button/resend-button.component";
import TopLeftLink from "../../../components/button/top-left-link.component";
import FormFieldTextWithIcon from "../../../components/forms/form-field-text-with-icon.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Form from "../../../components/forms/form.component";
import routes from "../../../components/navigation/routes";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";
import TopTitleBox from "../../../components/utils/top-title-box.component";
import {
  resendVerificationCodeEmail,
  resetPassword,
  smsVerificationCode,
} from "../../../services/auth/auth-slice.service";
import ResetSuccessful from "../components/forgot_password/password-reset-successful.component";

const validationSchema = Yup.object().shape({
  type: Yup.string().required().label("Type"),
  typeInfo: Yup.string().required().label("Type info"),
  verificationCode: Yup.number().required().label("Verification Code"),
  password: Yup.string().required().min(4).label("Password"),
  confirmPassword: Yup.string()
    .required()
    .oneOf([Yup.ref("password"), null], "Password and confirm password must be same.")
    .label("Password confirmation"),
});

function ForgotPasswordVerificationCodeScreen() {
  const theme = useTheme();
  const location = useLocation();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const formRef = useRef();
  const createSnackBar = useContext(SnackbarContext);
  const [isLoading, setIsLoading] = useState(false);
  const [verificationSuccess, setVerificationSuccess] = useState(false);

  const handleSubmitResetPassword = (values) => {
    setIsLoading(true);
    dispatch(resetPassword(values)).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
        setVerificationSuccess(true);
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleSubmitResendCodes = () => {
    let resendFunction = null;
    const values = {};
    values.type = "forgotPassword";

    if (location.state.type === "email") {
      resendFunction = resendVerificationCodeEmail;
      values.email = location.state.typeInfo;
    } else {
      resendFunction = smsVerificationCode;
      values.typeInfo = location.state.typeInfo;
    }
    dispatch(resendFunction(values)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.colors.bg.primary,
        minHeight: "100vh",
        opacity: 1,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <TopLeftLink label="< Back" pushTo={routes.LOGIN} />

      <Grid
        container
        sx={{
          width: "100%",
          flexDirection: "column",
        }}
      >
        <PaddedView multiples={isMobile ? 0 : 4}>
          <Grid item>
            <Grid
              container
              sx={{
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Grid item xs={12} sx={{ height: isMobile ? 45 : 60, textAlign: "center" }}>
                <KatchLogo color="green" />
              </Grid>
              <Spacer size="m" />
              {verificationSuccess ? (
                <ResetSuccessful />
              ) : (
                <Box>
                  <Box
                    sx={{
                      backgroundColor: theme.palette.colors.bg.white,
                      borderRadius: `${theme.shape.borderRadius[2]}px`,
                      flex: 1,
                      maxWidth: 500,
                      minWidth: isMobile ? null : 450,
                      alignItems: "center",
                      justifyContent: "center",
                      boxShadow: 2,
                      overflow: "hidden",
                    }}
                  >
                    <TopTitleBox title="Forgot Password Verification" requireCloseButton={false} />

                    <PaddedView multiples={isMobile ? 2 : 4}>
                      <Grid item xs={12}>
                        <Typography
                          sx={{ color: theme.palette.colors.text.primary }}
                          fontWeight="bold"
                        >
                          Enter verification code
                        </Typography>
                      </Grid>
                      <Spacer />
                      <Grid item xs={12}>
                        <Typography sx={{ color: theme.palette.colors.text.primary }}>
                          A verification code has been sent to {location.state.typeInfo}
                        </Typography>
                      </Grid>
                      <Spacer size="l" />
                      <Form
                        initialValues={{
                          type: location.state.type,
                          typeInfo: location.state.typeInfo,
                          verificationCode: "",
                          password: "",
                          confirmPassword: "",
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmitResetPassword}
                        innerRef={formRef}
                      >
                        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                          <ReactCodeInput
                            fieldWidth={50}
                            onChange={(value) =>
                              formRef.current.setFieldValue("verificationCode", value)
                            }
                          />
                        </Grid>
                        <Spacer size="s" />
                        <Grid item xs={12} textAlign="center">
                          <ResendButton handleSubmit={handleSubmitResendCodes} />
                        </Grid>
                        <Spacer size="l" />

                        <Grid item xs={12} textAlign="center">
                          <FormFieldTextWithIcon
                            name="password"
                            type="password"
                            placeholder="Password"
                            showIcon={true}
                            icon={<LockOutlinedIcon color="primary" />}
                          />
                          <Spacer size="m" />
                        </Grid>
                        <Spacer size="m" />

                        <Grid item xs={12} textAlign="center">
                          <FormFieldTextWithIcon
                            name="confirmPassword"
                            type="password"
                            placeholder="Confirm Password"
                            showIcon={true}
                            icon={<LockOutlinedIcon color="primary" />}
                          />
                        </Grid>
                        <Spacer size="xl" />
                        <Grid item textAlign="center" xs={12}>
                          <FormSubmitButton isLoading={isLoading} width="60%" borderRadius={3}>
                            <Typography>Confirm</Typography>
                          </FormSubmitButton>
                        </Grid>
                      </Form>
                    </PaddedView>
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>
        </PaddedView>
      </Grid>
    </Box>
  );
}

export default ForgotPasswordVerificationCodeScreen;
