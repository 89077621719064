import { Box, Grid, Modal, styled, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import CustomButton from "../../../components/button/custom-button.component";
import Spacer from "../../../components/utils/spacer.component";
import NotificationTab from "./notification-tab.component";

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  backgroundColor: theme.palette.colors.brand.white,
  boxShadow: 24,
  width: "100%",
  height: "100%",
  padding: "10px",
  paddingRight: "0px",
}));

function PhoneViewNotificationModal({ showModal, setShowModal }) {
  const theme = useTheme();

  return (
    <Modal open={showModal}>
      <ModalBox>
        <Box position="absolute">
          <CustomButton
            width="auto"
            variant="text"
            fontSize={theme.fonts.fontSizes.size15}
            onClick={() => setShowModal(false)}
          >
            {"< Back"}
          </CustomButton>
        </Box>
        <Grid container display="flex" alignItems="center">
          <Grid item xs={12}>
            <Typography textAlign="center" fontSize={theme.fonts.fontSizes.size20} paddingTop="5px">
              Notifications
            </Typography>
          </Grid>
        </Grid>
        <Spacer size="s" /> <NotificationTab setShowNotification={setShowModal} />
      </ModalBox>
    </Modal>
  );
}

PhoneViewNotificationModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};

export default PhoneViewNotificationModal;
