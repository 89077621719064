import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Box, Grid, Link, Typography, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import * as Yup from "yup";
import FormCheckBox from "../../../../components/forms/form-checkbox.component";
import FormFieldTextWithIcon from "../../../../components/forms/form-field-text-with-icon.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import Form from "../../../../components/forms/form.component";
import routes from "../../../../components/navigation/routes";
import PaddedView from "../../../../components/utils/padded-view.component";
import Spacer from "../../../../components/utils/spacer.component";
import TopTitleBox from "../../../../components/utils/top-title-box.component";
import LabelLink from "../link-with-label.component";

function LoginForm({ handleSubmit, isLoading }) {
  const validationSchema = Yup.object().shape({
    email: Yup.string().required().email().label("Email"),
    password: Yup.string().required().min(4).label("Password"),
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: theme.palette.colors.bg.white,
          borderRadius: `${theme.shape.borderRadius[2]}px`,
          flex: 1,
          maxWidth: 500,
          minWidth: isMobile ? null : 450,
          alignItems: "center",
          justifyContent: "center",
          boxShadow: 2,
          overflow: "hidden",
        }}
      >
        <TopTitleBox title="Sign In" requireCloseButton={false} />
        <Form
          initialValues={{
            email: localStorage.getItem("userEmail") || "",
            password: localStorage.getItem("userPassword") || "",
            isRemember: localStorage.getItem("isRemember") === "true" || false,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <PaddedView multiples={isMobile ? 3 : 4}>
            <FormFieldTextWithIcon
              name="email"
              placeholder="Enter your email address"
              showIcon={true}
              icon={<MailOutlineIcon color="primary" />}
            />

            <Spacer size="m" />
            <FormFieldTextWithIcon
              name="password"
              placeholder="Password"
              showIcon={true}
              icon={<LockOutlinedIcon color="primary" />}
            />

            <Grid container justifyContent="space-between" sx={{ paddingTop: 1 }}>
              <Grid sx={{ width: 150 }}>
                <FormCheckBox name="isRemember" label="Remember Me" />
              </Grid>
              <Grid alignItems="center" justifyContent="center">
                <Link
                  sx={{
                    paddingLeft: 1,
                    color: theme.palette.colors.brand.primary,
                  }}
                  underline="none"
                  key="1"
                  variant="body2"
                  href={routes.FORGOTPASSWORD}
                >
                  Forgot Password?
                </Link>
              </Grid>
            </Grid>
            <Spacer size="xl" />
            <LabelLink label=" Don't have an account? " link="Sign Up" pushTo={routes.SIGNUP} />

            <Spacer size="s" />
            <Grid item textAlign="center" xs={12}>
              <FormSubmitButton isLoading={isLoading} width="60%" borderRadius={3}>
                <Typography>Sign In</Typography>
              </FormSubmitButton>
            </Grid>
          </PaddedView>
        </Form>
      </Box>
    </Box>
  );
}

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default LoginForm;
