import PlaceIcon from "@mui/icons-material/Place";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { format } from "date-fns";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import PaddedView from "../../../../components/utils/padded-view.component";
import Spacer from "../../../../components/utils/spacer.component";
import { profileSelector } from "../../../../services/profile/profile-slice.service";

function BookingDetailsCard({ itemDetails }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { getProfileDetailObj } = useSelector(profileSelector);

  const renderPriceOrCode = () => {
    if (
      itemDetails.booking.type === "merchant_class_session" ||
      itemDetails.booking.type === "merchant_appointment"
    ) {
      if (itemDetails.booking.status === "merchant_request") {
        return `REQUESTED`;
      }

      if (itemDetails.booking.package.code) {
        return itemDetails.booking.package.code;
      }
    }

    return `RM${itemDetails.booking.price}`;
  };

  return (
    <Box
      sx={{
        height: "100%",
        backgroundColor: theme.palette.colors.bg.primary,
        borderRadius: `${theme.shape.borderRadius[2]}px`,
        cursor: "pointer",
      }}
    >
      <PaddedView multiples={3}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Grid item>
              <Typography
                fontWeight="bold"
                fontSize={isMobile ? theme.fonts.fontSizes.size18 : theme.fonts.fontSizes.size20}
              >
                {format(new Date(itemDetails.booking.date), "d MMM yyyy, E")}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                {itemDetails.booking.type !== "merchant_class_session" &&
                itemDetails.booking.type !== "merchant_appointment"
                  ? itemDetails.booking.time
                  : `${itemDetails.booking.session.startTime} (${itemDetails.booking.session.durationMins}mins)`}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              item
              sx={{
                backgroundColor:
                  renderPriceOrCode() === "REQUESTED"
                    ? theme.palette.colors.ui.pending
                    : theme.palette.colors.brand.primary,
                alignSelf: "center",
                borderRadius: `${theme.shape.borderRadius[5]}px`,
                paddingY: "5px",
                paddingX: "20px",
              }}
            >
              <Typography
                fontWeight="bold"
                sx={{ color: "#fff" }}
                fontSize={theme.fonts.fontSizes.size14}
                textAlign="center"
              >
                {renderPriceOrCode()}
              </Typography>
            </Grid>
            {itemDetails.booking.location.requireVerification &&
              renderPriceOrCode() === "REQUESTED" &&
              getProfileDetailObj.status === "succeeded" &&
              getProfileDetailObj.data &&
              getProfileDetailObj.data.status !== "verified" && (
                <Grid item>
                  <Typography
                    fontWeight="bold"
                    color={theme.palette.colors.text.error}
                    fontSize={theme.fonts.fontSizes.size14}
                  >
                    Verification Required
                  </Typography>
                </Grid>
              )}
          </Grid>
        </Grid>
        <Spacer size="m" />
        <Grid item>
          <Typography textTransform="uppercase" fontSize={theme.fonts.fontSizes.size14}>
            {(itemDetails.booking.type === "gym_session" ||
              itemDetails.booking.type === "merchant_hourly_booking") &&
              "gym session"}
            {itemDetails.booking.type === "merchant_class_session" &&
              itemDetails.booking.session.class.category.label}
            {itemDetails.booking.type === "merchant_event_attendee" && "EVENT"}
          </Typography>
        </Grid>
        {itemDetails.booking.type === "merchant_class_session" && (
          <>
            <Grid item>
              <Typography
                fontWeight="bold"
                fontSize={isMobile ? theme.fonts.fontSizes.size18 : theme.fonts.fontSizes.size20}
              >
                {itemDetails.booking.session.class.name}
              </Typography>
            </Grid>
            <Grid container columnSpacing={1}>
              {itemDetails.booking.session.staffs.map((item, index) => (
                <Grid item key={item.id}>
                  <Typography>
                    {item.firstName} {item.lastName}
                    {index !== itemDetails.booking.session.staffs.length - 1 && ","}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </>
        )}
        {itemDetails.booking.type === "merchant_appointment" && (
          <>
            <Grid item>
              <Typography fontWeight="bold" color="primary">
                WELLNESS
              </Typography>
            </Grid>
            <Grid item>
              <Typography fontWeight="bold">{itemDetails.booking.session.class.name}</Typography>
            </Grid>
          </>
        )}
        {itemDetails.booking.type === "merchant_event_attendee" && (
          <Grid item>
            <Typography
              fontWeight="bold"
              fontSize={isMobile ? theme.fonts.fontSizes.size18 : theme.fonts.fontSizes.size20}
              sx={{ textTransform: "capitalize" }}
            >
              {itemDetails.booking.session.title
                ? itemDetails.booking.session.title
                : itemDetails.booking.session.event.title}
            </Typography>
          </Grid>
        )}

        <Spacer size="m" />
        <Grid item display="flex" alignContent="center">
          <PlaceIcon color="primary" />
          <Spacer position="left" />
          <Typography>{itemDetails.booking.location.name}</Typography>
        </Grid>
      </PaddedView>
    </Box>
  );
}

BookingDetailsCard.defaultProps = {
  itemDetails: PropTypes.shape({
    id: null,
    booking: PropTypes.shape({
      status: "",
      type: "",
      date: "",
      time: "",
      price: "",
      paymentMethod: "",
      package: PropTypes.shape({
        code: "",
      }),
      session: PropTypes.shape({
        title: "",
        durationMins: 0,
        startTime: "",
        class: PropTypes.shape({
          name: "",
          category: PropTypes.shape({
            label: "",
          }),
        }),
        event: PropTypes.shape({
          title: "",
        }),
        staffs: PropTypes.arrayOf(
          PropTypes.shape({
            id: null,
            firstName: "",
            lastName: "",
          }),
        ),
      }),
      location: PropTypes.shape({
        name: "",
        requireVerification: false,
      }),
    }),
    editable: false,
    editableBy: "",
    nonRefundable: false,
  }),
};

BookingDetailsCard.propTypes = {
  itemDetails: PropTypes.shape({
    id: PropTypes.number,
    booking: PropTypes.shape({
      status: PropTypes.string,
      type: PropTypes.string,
      date: PropTypes.string,
      time: PropTypes.string,
      price: PropTypes.string,
      paymentMethod: PropTypes.string,
      package: PropTypes.shape({
        code: PropTypes.string,
      }),
      session: PropTypes.shape({
        title: PropTypes.string,
        durationMins: PropTypes.number,
        startTime: PropTypes.string,
        class: PropTypes.shape({
          name: PropTypes.string,
          category: PropTypes.shape({
            label: PropTypes.string,
          }),
        }),
        event: PropTypes.shape({
          title: PropTypes.string,
        }),
        staffs: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            firstName: PropTypes.string,
            lastName: PropTypes.string,
          }),
        ),
      }),
      location: PropTypes.shape({
        name: PropTypes.string,
        requireVerification: PropTypes.bool,
      }),
    }),
    editable: PropTypes.bool,
    editableBy: PropTypes.string,
    nonRefundable: PropTypes.bool,
  }),
};

export default BookingDetailsCard;
