import { Box, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { format } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../components/button/custom-button.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import ItemCarousel from "../../../components/utils/item-carousel.component";
import CustomSkeleton from "../../../components/utils/skeleton.component";
import Spacer from "../../../components/utils/spacer.component";
import { businessSelector } from "../../../services/business/business-slice.service";
import { getFitnessClasses } from "../../../services/search/fitness-class/search-fitness-class-slice.service";
import { searchSelector } from "../../../services/search/search-slice.service";
import ClassResultCard from "../../explore/components/class/class-result-card.component";
import AvailableClassSessionModal from "./available-class-session-modal.component";

const SectionText = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size18,
  fontWeight: "bold",
}));

function FitnessClassSection() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const { reduxStoreTimeIndex } = useSelector(searchSelector);
  const { getBusinessDetailsObj } = useSelector(businessSelector);
  const createSnackBar = useContext(SnackbarContext);
  const [availableSessions, setAvailableSessions] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const currentTime = format(new Date(), "HH:mm:ss");
    const timeOffset = reduxStoreTimeIndex.find((item) => item.time > currentTime).value;
    const startAt = `${format(new Date(), "yyyy-MM-dd")} ${reduxStoreTimeIndex[timeOffset].time}`;

    let endAt = new Date();
    endAt.setDate(endAt.getDate() + 7);
    endAt = format(endAt, "yyyy-MM-dd 23:30:00");

    dispatch(
      getFitnessClasses({
        startAt,
        endAt,
        businessesId: getBusinessDetailsObj.data.id,
        lat: parseFloat(getBusinessDetailsObj.data.location.lat),
        long: parseFloat(getBusinessDetailsObj.data.location.long),
        distance: 30,
        fitnessCategoriesId: [],
        page: 1,
      }),
    ).then(({ payload, meta, error }) => {
      setAvailableSessions(payload.data.items);
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  const renderResult = () => {
    if (availableSessions) {
      if (availableSessions.length > 0) {
        if (isMobile) {
          return availableSessions.slice(0, 2).map((item) => (
            <Grid item key={item.id} xs={isMobile ? 12 : 6}>
              <ClassResultCard itemDetails={item} />
              <Spacer />
            </Grid>
          ));
        }
        return (
          <ItemCarousel slidesToScroll={2} slidesToShow={2}>
            {availableSessions.map((item) => (
              <Box key={item.id}>
                <ClassResultCard itemDetails={item} />
              </Box>
            ))}
          </ItemCarousel>
        );
      }

      return (
        <Grid container display="flex" justifyContent="center">
          No upcoming classes.
        </Grid>
      );
    }

    return (
      <Grid item container columnSpacing={2} rowSpacing={1}>
        <Grid item xs={isMobile ? 12 : 6}>
          <CustomSkeleton width="100%" height={208} />
        </Grid>
        <Grid item xs={isMobile ? 12 : 6}>
          <CustomSkeleton width="100%" height={208} />
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid item xs={12}>
      <AvailableClassSessionModal showModal={showModal} setShowModal={setShowModal} />
      <Grid container display="flex" justifyContent="space-between">
        <Grid item display="flex" alignItems="center">
          <SectionText>Upcoming Classes</SectionText>
        </Grid>
        <Grid item>
          <CustomButton
            variant="outlined"
            fontSize={theme.fonts.fontSizes.size14}
            onClick={() => setShowModal(true)}
          >
            See All
          </CustomButton>
        </Grid>
      </Grid>
      <Spacer size="m" />
      {!isMobile ? (
        <Grid item xs={12}>
          {renderResult()}
        </Grid>
      ) : (
        <Grid container columnSpacing={2} rowSpacing={1}>
          {renderResult()}
        </Grid>
      )}
    </Grid>
  );
}

export default FitnessClassSection;
