import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Avatar, Box, Button, Grid, Icon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import { uploadImage } from "../../../services/profile/profile-slice.service";

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

function PhotoUpload({ name, imagePath }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [filePreview, setFilePreview] = useState(imagePath);
  const createSnackBar = useContext(SnackbarContext);

  const uploadProfileImage = (values) => {
    dispatch(uploadImage(values)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  return (
    <Grid container flexDirection="column" alignItems="center" justifyContent="center">
      <Grid item>
        <Button
          component="label"
          sx={{
            backgroundColor: "transparent",
            borderTopLeftRadius: theme.shape.borderRadius[1],
            borderTopRightRadius: theme.shape.borderRadius[1],
            borderBottomLeftRadius: theme.shape.borderRadius[1],
            borderBottomRightRadius: theme.shape.borderRadius[1],
            "&:hover": {
              background: "none",
            },
          }}
        >
          <Box sx={{ position: "relative" }}>
            <Avatar
              sx={{
                width: 75,
                height: 75,
                backgroundColor: theme.palette.colors.ui.secondary,
              }}
              src={filePreview}
            />
            {!filePreview && (
              <Icon
                size="large"
                sx={{
                  position: "absolute",
                  left: "50px",
                  top: "50px",
                  height: "20px",
                  overflow: "visible",
                  color: theme.palette.colors.brand.primary,
                  borderRadius: "50%",
                }}
              >
                <AddCircleIcon sx={{ height: "100%", width: "20px" }} />
              </Icon>
            )}
          </Box>
          {!filePreview && (
            <input
              type="file"
              name={name}
              hidden
              accept="image/*"
              onChange={(e) => {
                const { files } = e.target;
                if (files) {
                  const myFile = files[0]; // single file upload only
                  const withinFileSizeLimit = myFile && myFile.size <= 5000000; // 1mb = 1^6 bytes
                  const allowedFileType = myFile && SUPPORTED_FORMATS.includes(myFile.type);
                  if (myFile && withinFileSizeLimit) {
                    const fileReader = new FileReader(); // to preview the image
                    fileReader.onload = () => {
                      if (fileReader.readyState === 2) {
                        setFilePreview(fileReader.result);
                        const img = new Image();
                        img.src = URL.createObjectURL(myFile);
                        img.onload = () => {
                          const canvas = document.createElement("canvas");
                          canvas.width = 500;
                          canvas.height = 500;
                          const ctx = canvas.getContext("2d");
                          const scaleFactor = Math.min(
                            canvas.width / img.width,
                            canvas.height / img.height,
                          );

                          const newWidth = img.width * scaleFactor;
                          const newHeight = img.height * scaleFactor;

                          const x = canvas.width / 2 - newWidth / 2;
                          const y = canvas.height / 2 - newHeight / 2;

                          ctx.drawImage(img, x, y, newWidth, newHeight);
                          const dataURL = canvas.toDataURL("image/webp");
                          uploadProfileImage({ image: dataURL });
                        };
                      }
                    };
                    fileReader.readAsDataURL(myFile);
                  } else if (!withinFileSizeLimit) {
                    createSnackBar({
                      message: "Image file cannot be more than 5mb",
                      type: "error",
                      open: true,
                    });
                    setFilePreview(imagePath);
                  } else if (!allowedFileType) {
                    createSnackBar({
                      message: `Only ${SUPPORTED_FORMATS} are allowed`,
                      type: "error",
                      open: true,
                    });
                    setFilePreview(imagePath);
                  }
                }
              }}
            />
          )}
        </Button>
      </Grid>
    </Grid>
  );
}

PhotoUpload.defaultProps = {
  imagePath: "",
};

PhotoUpload.propTypes = {
  name: PropTypes.string.isRequired,
  imagePath: PropTypes.string,
};

export default PhotoUpload;
