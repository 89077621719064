import CloseIcon from "@mui/icons-material/Close";
import { Box, CardMedia, Grid, IconButton, Modal, styled, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  notificationSelector,
  readNotification,
} from "../../../services/notification/notification-slice.service";
import PopUpsImageCarousel from "./pop-ups-image-carousel.component";

const ModalBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ isMobile }) => ({
  position: "absolute",
  top: !isMobile && "50%",
  left: !isMobile && "50%",
  transform: !isMobile && "translate(-50%, -50%)",
  boxShadow: 24,
  width: "90%",
  maxWidth: "400px",
  outline: "none",
  overflowY: "auto",
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  height: "25px",
  width: "25px",
  margin: "auto",
  color: theme.palette.colors.brand.secondary,
}));

const StyledCloseIcon = styled(CloseIcon)(({ theme }) => ({
  height: "40px",
  width: "40px",
  backgroundColor: theme.palette.colors.brand.primary,
  borderRadius: "50%",
  padding: "5px",
  strokeWidth: 0.5,
  color: "#fff",
}));

function PopUpsModal() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { popUpsObj } = useSelector(notificationSelector);
  const [showModal, setShowModal] = useState(false);
  const [totalIndex, setTotalIndex] = useState(0);
  const [showIndex, setShowIndex] = useState(0);

  const localStateUpdate = () => {
    dispatch(readNotification(popUpsObj.data[showIndex].id));

    setShowIndex(showIndex + 1);
    if (totalIndex === showIndex) {
      setShowModal(false);
    } else {
      setShowModal(true);
    }
  };

  useEffect(() => {
    if (popUpsObj.status === "succeeded" && popUpsObj.data && popUpsObj.data.length > 0) {
      setShowModal(true);
      setTotalIndex(popUpsObj.data.length - 1);
    }
  }, [popUpsObj.status]);

  return (
    popUpsObj.status === "succeeded" &&
    popUpsObj.data &&
    popUpsObj.data.length > 0 && (
      <Modal open={showModal}>
        <ModalBox>
          {popUpsObj.data.map(
            (notification, index) =>
              index === showIndex && (
                <Box key={notification.id}>
                  {notification.popUp.type === "images" && (
                    <PopUpsImageCarousel imageList={notification.popUp.attachments} />
                  )}
                  {notification.popUp.type === "video" && (
                    <CardMedia
                      component="video"
                      autoPlay
                      muted
                      controls
                      allow="autoPlay"
                      src={notification.popUp.attachments[0]}
                      sx={{ height: "70vh", backgroundColor: theme.palette.colors.bg.secondary }}
                    />
                  )}
                </Box>
              ),
          )}
          <Grid container display="flex" justifyItems="center" marginY="20px">
            <CloseIconButton onClick={localStateUpdate}>
              <StyledCloseIcon />
            </CloseIconButton>
          </Grid>
        </ModalBox>
      </Modal>
    )
  );
}

export default PopUpsModal;
