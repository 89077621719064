import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Grid, Pagination, styled, Tab } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomSkeleton from "../../../components/utils/skeleton.component";
import Spacer from "../../../components/utils/spacer.component";
import {
  getNotifications,
  notificationSelector,
} from "../../../services/notification/notification-slice.service";
import NotificationDetailsModal from "./notification-details-modal.component";
import NotificationList from "./notification-list.component";

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: "none",
  fontSize: theme.fonts.fontSizes.size15,
}));

const StyledTabPanel = styled(TabPanel)(() => ({
  padding: 0,
}));

function NotificationTab({ setShowNotification }) {
  const dispatch = useDispatch();
  const { notificationIndexObj } = useSelector(notificationSelector);
  const [tabs, setTabs] = useState("all");
  const [notificationContent, setNotificationContent] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [notificationPage, setNotificationPage] = useState(1);

  const getNotificationsByGroup = (group, page) => {
    dispatch(getNotifications({ group, page }));
  };

  const handleChange = (event, newValue) => {
    setNotificationPage(1);
    setTabs(newValue);
    getNotificationsByGroup(newValue, 1);
  };

  useEffect(() => {
    getNotificationsByGroup("all", 1);
  }, []);

  const renderLoader = () => (
    <Grid item padding="10px">
      <CustomSkeleton width="100%" height={61} />
      <Spacer />
      <CustomSkeleton width="100%" height={61} />
    </Grid>
  );

  const onPageChange = (event, newPage) => {
    setNotificationPage(newPage);
    getNotificationsByGroup(tabs, newPage);
  };

  const renderNotificationTabPanel = () => {
    if (notificationIndexObj.status === "succeeded") {
      return (
        <>
          <NotificationList
            notifications={notificationIndexObj.data.items}
            setNotificationContent={setNotificationContent}
            setShowDetailsModal={setShowDetailsModal}
            setShowNotification={setShowNotification}
          />
          <Spacer size="m" />
          {notificationIndexObj.data.items.length > 0 && (
            <Grid container justifyContent="center" marginBottom="10px">
              <Pagination
                size="small"
                page={notificationPage}
                onChange={onPageChange}
                count={notificationIndexObj.data.pagination.totalPages}
                variant="outlined"
              />
            </Grid>
          )}
        </>
      );
    }

    return renderLoader();
  };

  return (
    <TabContext value={tabs}>
      <NotificationDetailsModal
        notificationDetails={notificationContent}
        showModal={showDetailsModal}
        setShowModal={setShowDetailsModal}
      />
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <TabList onChange={handleChange} variant="fullWidth">
          <StyledTab label="All" value="all" />
          <StyledTab label="Read" value="read" />
        </TabList>
      </Box>
      <Box>
        <StyledTabPanel value="all">{renderNotificationTabPanel()}</StyledTabPanel>
        <StyledTabPanel value="read">{renderNotificationTabPanel()} </StyledTabPanel>
      </Box>
    </TabContext>
  );
}

NotificationTab.propTypes = {
  setShowNotification: PropTypes.func.isRequired,
};

export default NotificationTab;
