import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import {
  Box,
  ButtonBase,
  Grid,
  Icon,
  Modal,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../components/button/custom-button.component";
import routes from "../../../components/navigation/routes";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";
import TopTitleBox from "../../../components/utils/top-title-box.component";
import { eventSessionSelector } from "../../../services/event/session/event-session-slice.service";

const ModalBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  position: "absolute",
  top: !isMobile && "50%",
  left: !isMobile && "50%",
  transform: !isMobile && "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.white,
  borderRadius: !isMobile && theme.shape.borderRadius[2],
  boxShadow: 24,
  maxWidth: !isMobile && "550px",
  width: isMobile ? "100%" : "90%",
  height: isMobile && "100%",
  outline: "none",
}));

function SessionCategoryModal({ showModal, setShowModal }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { getEventSessionDetailsObj } = useSelector(eventSessionSelector);
  const navigate = useNavigate();
  const [selectedCategories, setSelectedCategories] = useState([]);

  const handleSelect = (category) => {
    if (selectedCategories.includes(category.id)) {
      setSelectedCategories(selectedCategories.filter((selected) => selected !== category.id));
    } else {
      setSelectedCategories([...selectedCategories, category.id]);
    }
  };

  const renderSessionCategories = () => (
    <Grid container item columnSpacing={1} rowSpacing={1}>
      {getEventSessionDetailsObj.data.sessionCategories.map((item) => (
        <ButtonBase
          key={item.id}
          disabled={item.booked || item.maxCapacity}
          onClick={() => handleSelect(item)}
          sx={{
            width: "100%",
            border: "1px solid",
            borderColor: selectedCategories.includes(item.id)
              ? theme.palette.colors.brand.primary
              : theme.palette.colors.ui.border,
            borderRadius: `${theme.shape.borderRadius[1]}px`,
            padding: "15px",
            marginBottom: "10px",
            opacity: item.booked || item.maxCapacity ? "60%" : "100%",
          }}
        >
          <Grid item xs={12}>
            <Grid item display="flex">
              <Typography
                fontWeight={600}
                color={
                  selectedCategories.includes(item.id)
                    ? theme.palette.colors.brand.primary
                    : theme.palette.colors.text.primary
                }
              >
                {item.label}
              </Typography>
            </Grid>
            <Spacer />
            <Grid container justifyContent="space-between">
              <Grid item display="flex">
                <CalendarMonthOutlinedIcon
                  color={selectedCategories.includes(item.id) ? "primary" : ""}
                />
                <Spacer position="left" />
                <Typography
                  sx={{
                    color: selectedCategories.includes(item.id)
                      ? theme.palette.colors.brand.primary
                      : theme.palette.colors.text.tertiary,
                  }}
                >
                  {item.date}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    color: selectedCategories.includes(item.id)
                      ? theme.palette.colors.brand.primary
                      : theme.palette.colors.text.tertiary,
                  }}
                  fontWeight={600}
                >
                  RM {item.price}
                </Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between">
              <Grid item display="flex">
                <AccessTimeOutlinedIcon
                  color={selectedCategories.includes(item.id) ? "primary" : ""}
                />
                <Spacer position="left" />
                <Typography
                  sx={{
                    color: selectedCategories.includes(item.id)
                      ? theme.palette.colors.brand.primary
                      : theme.palette.colors.text.tertiary,
                  }}
                >
                  {item.time}
                </Typography>
              </Grid>
              {item.booked && (
                <Grid item>
                  <Typography fontWeight="bold" color="primary">
                    BOOKED
                  </Typography>
                </Grid>
              )}
              {item.maxCapacity && !item.booked && (
                <Grid item>
                  <Typography fontWeight="bold" color="error">
                    FULLY BOOKED
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>

          {selectedCategories.includes(item.id) && (
            <Icon
              sx={{
                position: "absolute",
                right: -5,
                top: 0,
                backgroundColor: theme.palette.colors.bg.white,
                borderRadius: "50%",
              }}
            >
              <CheckCircleRoundedIcon sx={{ color: theme.palette.colors.text.success }} />
            </Icon>
          )}
        </ButtonBase>
      ))}
    </Grid>
  );

  return (
    <Modal open={showModal} onClose={() => setShowModal(false)}>
      <ModalBox isMobile={isMobile}>
        <PaddedView multiples={2}>
          <TopTitleBox
            title="Session Categories"
            requireCloseButton={true}
            setShowModal={setShowModal}
          />
          <Spacer position="top" size="l" />
          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            rowSpacing={3}
            padding="10px"
            sx={{
              height: isMobile ? "78vh" : "500px",
              overflow: "auto",
            }}
          >
            {renderSessionCategories()}
          </Grid>
          <Grid item paddingTop="10px">
            <CustomButton
              disabled={selectedCategories.length === 0}
              fontSize={theme.fonts.fontSizes.size16}
              width="100%"
              onClick={() => {
                navigate(routes.EVENTBOOKINGSUMMARY, {
                  state: {
                    eventSessionId: getEventSessionDetailsObj.data.id,
                    eventSessionCategoryIds: selectedCategories,
                  },
                });
              }}
            >
              Book
            </CustomButton>
          </Grid>
        </PaddedView>
      </ModalBox>
    </Modal>
  );
}

SessionCategoryModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};

export default SessionCategoryModal;
