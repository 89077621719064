import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq, putReq, postReq } from "../api.services";

export const getNotifications = createAsyncThunk("notifications", async (payload) => {
  const { page, group } = payload;
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/notifications?group=${group}&page=${page}`,
  );
  return response;
});

export const getNotificationStatus = createAsyncThunk("notifications/status", async () => {
  const response = await getReq(`${process.env.REACT_APP_API_PRIVATE_V1}/notifications/status`);
  return response;
});

export const readNotification = createAsyncThunk("notification/read", async (payload) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/notifications/${payload}/read`,
  );
  return response;
});

export const getPopUps = createAsyncThunk("notification/pop_ups", async () => {
  const response = await getReq(`${process.env.REACT_APP_API_PRIVATE_V1}/notifications/pop_ups`);
  return response;
});

export const updateNewsletterPreferences = createAsyncThunk(
  "notification/newsletter_preferences",
  async () => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/message_settings/newsletter`,
    );
    return response;
  },
);

const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    notificationIndexObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    notificationStatusObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    readNotificationObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    popUpsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    newsletterPreferences: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {
    updateNotificationIndex: (state, action) => {
      state.notificationIndexObj.data.items = action.payload.updatedNotificationIndex;
    },
  },
  extraReducers: {
    [getNotifications.pending]: (state) => {
      state.notificationIndexObj.status = "pending";
    },
    [getNotifications.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.notificationIndexObj.status = "succeeded";
      state.notificationIndexObj.data = data;
      state.notificationIndexObj.successMessage = message;
    },
    [getNotifications.rejected]: (state, action) => {
      const { message } = action.error;

      state.notificationIndexObj.status = "rejected";
      state.notificationIndexObj.errorMessage = message;
    },
    [getNotificationStatus.pending]: (state) => {
      state.notificationStatusObj.status = "pending";
    },
    [getNotificationStatus.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.notificationStatusObj.status = "succeeded";
      state.notificationStatusObj.data = data;
      state.notificationStatusObj.successMessage = message;
    },
    [getNotificationStatus.rejected]: (state, action) => {
      const { message } = action.error;

      state.notificationStatusObj.status = "rejected";
      state.notificationStatusObj.errorMessage = message;
    },
    [readNotification.pending]: (state) => {
      state.readNotificationObj.status = "pending";
    },
    [readNotification.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.readNotificationObj.status = "succeeded";
      state.readNotificationObj.data = data;
      state.readNotificationObj.successMessage = message;
    },
    [readNotification.rejected]: (state, action) => {
      const { message } = action.error;

      state.readNotificationObj.status = "rejected";
      state.readNotificationObj.errorMessage = message;
    },
    [getPopUps.pending]: (state) => {
      state.popUpsObj.status = "pending";
    },
    [getPopUps.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.popUpsObj.status = "succeeded";
      state.popUpsObj.data = data;
      state.popUpsObj.successMessage = message;
    },
    [getPopUps.rejected]: (state, action) => {
      const { message } = action.error;

      state.popUpsObj.status = "rejected";
      state.popUpsObj.errorMessage = message;
    },
    [updateNewsletterPreferences.pending]: (state) => {
      state.newsletterPreferences.status = "pending";
    },
    [updateNewsletterPreferences.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.newsletterPreferences.status = "succeeded";
      state.newsletterPreferences.data = data;
      state.newsletterPreferences.successMessage = message;
      // userStorageService.storeUser(data);
    },
    [updateNewsletterPreferences.rejected]: (state, action) => {
      const { message } = action.error;

      state.newsletterPreferences.status = "rejected";
      state.newsletterPreferences.errorMessage = message;
    },
  },
});

export default notificationSlice.reducer;

// state
export const notificationSelector = (state) => state.notification;

export const { updateNotificationIndex } = notificationSlice.actions;
