import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useNavigate } from "react-router-dom";
import routes from "../../../components/navigation/routes";
import Spacer from "../../../components/utils/spacer.component";

function SubscriptionPlanCard({ itemDetails }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  return (
    <Grid
      onClick={() =>
        navigate({
          pathname: routes.SUBSCRIPTIONPLANSUMMARY,
          search: `?planId=${itemDetails.id}`,
        })
      }
      container
      alignContent="center"
      sx={{
        backgroundColor: theme.palette.colors.bg.primary,
        borderRadius: `${theme.shape.borderRadius[2]}px`,
        objectFit: "cover",
        cursor: "pointer",
        "&:hover .planTitle": { color: theme.palette.colors.brand.primary },
      }}
    >
      <Grid item alignSelf="center" alignItems="center" display="flex" xs={4}>
        <img
          src={itemDetails.imagePath}
          alt="planImage"
          width="100%"
          height={isMobile ? "135px" : "auto"}
          style={{
            borderTopLeftRadius: `${theme.shape.borderRadius[2]}px`,
            borderBottomLeftRadius: `${theme.shape.borderRadius[2]}px`,
            objectFit: "cover",
          }}
        />
      </Grid>
      <Grid item display="flex" alignItems="center" xs={8} paddingLeft="10px">
        <Grid item>
          <Grid item>
            <Typography fontWeight="bold" className="planTitle">
              {itemDetails.title}
            </Typography>
          </Grid>
          <Spacer size="xs" />
          <Grid item display="flex" alignContent="center">
            <Typography fontSize={theme.fonts.fontSizes.size14}>
              {itemDetails.shortDescription}
            </Typography>
          </Grid>
          <Spacer size="xs" />
          <Grid item display="flex" alignContent="center">
            {itemDetails.strikePrice && (
              <Typography
                color={theme.palette.colors.text.secondary}
                sx={{ textDecoration: "line-through" }}
              >
                RM {itemDetails.strikePrice}
              </Typography>
            )}

            <Spacer size="xs" position="left" />
            <Typography fontWeight="bold" color="primary">
              RM {itemDetails.price}
            </Typography>
          </Grid>
          <Spacer size="xs" />
          <Grid item display="flex" alignContent="center">
            <Typography fontWeight="600">As low as RM {itemDetails.pricePerDay} / Day</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

SubscriptionPlanCard.defaultProps = {
  itemDetails: PropTypes.shape({
    id: null,
    title: "",
    shortDescription: "",
    price: "",
    imagePath: "",
    purchasable: false,
    pricePerDay: "",
    strikePrice: "",
    numberOfDays: 0,
  }),
};

SubscriptionPlanCard.propTypes = {
  itemDetails: PropTypes.shape({
    id: PropTypes.number,
    numberOfDays: PropTypes.number,
    pricePerDay: PropTypes.string,
    strikePrice: PropTypes.string,
    title: PropTypes.string,
    shortDescription: PropTypes.string,
    price: PropTypes.string,
    imagePath: PropTypes.string,
    purchasable: PropTypes.bool,
  }),
};

export default SubscriptionPlanCard;
