import { Grid, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useNavigate } from "react-router-dom";
import routes from "../../../components/navigation/routes";
import Spacer from "../../../components/utils/spacer.component";

function PackagePlanCard({ businessId, itemDetails }) {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Grid
      onClick={() =>
        navigate({
          pathname: routes.PACKAGEPLANSUMMARY,
          search: `?businessId=${businessId}&planId=${itemDetails.id}`,
        })
      }
      container
      alignContent="center"
      sx={{
        backgroundColor: theme.palette.colors.bg.primary,
        borderRadius: `${theme.shape.borderRadius[2]}px`,
        cursor: "pointer",
      }}
    >
      <Grid item alignSelf="center" alignItems="center" display="flex" xs={4}>
        <img
          src={itemDetails.imagePath}
          alt="planImage"
          width="100%"
          style={{
            borderTopLeftRadius: `${theme.shape.borderRadius[2]}px`,
            borderBottomLeftRadius: `${theme.shape.borderRadius[2]}px`,
            objectFit: "cover",
          }}
        />
      </Grid>
      <Grid item xs={8} display="flex" alignItems="center" paddingLeft="10px">
        <Grid item>
          <Grid item>
            <Typography fontWeight="bold" sx={{ color: theme.palette.colors.brand.primary }}>
              {itemDetails.title}
            </Typography>
          </Grid>
          <Spacer />
          <Grid item display="flex" alignContent="center">
            <Typography>{itemDetails.noOfSessions} sessions</Typography>
          </Grid>
          <Typography>
            RM {(Number(itemDetails.price.replace(",", "")) / itemDetails.noOfSessions).toFixed(2)}{" "}
            / session
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

PackagePlanCard.defaultProps = {
  itemDetails: PropTypes.shape({
    id: null,
    title: "",
    noOfSessions: 0,
    price: "",
    imagePath: "",
    purchasable: false,
  }),
};

PackagePlanCard.propTypes = {
  businessId: PropTypes.number.isRequired,
  itemDetails: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    noOfSessions: PropTypes.number,
    price: PropTypes.string,
    imagePath: PropTypes.string,
    purchasable: PropTypes.bool,
  }),
};

export default PackagePlanCard;
