import { MenuItem, Select, styled, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { SnackbarContext } from "../../../../components/notification/snackbar.context";
import { getCreditTransactions } from "../../../../services/credit/credit-slice.service";

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size14,
}));

function FilterMenu({ transactionFilter, setTransactionFilter }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);

  const handleFilterChange = (event) => {
    setTransactionFilter(event.target.value);
    dispatch(getCreditTransactions({ type: event.target.value, page: 1 })).then(
      ({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      },
    );
  };

  return (
    <Select
      variant="standard"
      value={transactionFilter}
      onChange={handleFilterChange}
      disableUnderline={true}
      sx={{
        fontSize: theme.fonts.fontSizes.size14,
        "& .MuiSelect-select:focus": {
          backgroundColor: theme.palette.colors.bg.primary,
        },
      }}
      MenuProps={{
        PaperProps: {
          sx: {
            borderRadius: `${theme.shape.borderRadius[1]}px`,
            width: "120px",
          },
        },
      }}
    >
      <StyledMenuItem value="all">All</StyledMenuItem>
      <StyledMenuItem value="credit">Purchase</StyledMenuItem>
      <StyledMenuItem value="refund">Refund</StyledMenuItem>
      <StyledMenuItem value="top_up">Top-up</StyledMenuItem>
    </Select>
  );
}

FilterMenu.propTypes = {
  transactionFilter: PropTypes.string.isRequired,
  setTransactionFilter: PropTypes.func.isRequired,
};

export default FilterMenu;
