import { Box, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import CustomButton from "../../../components/button/custom-button.component";
import ItemCarousel from "../../../components/utils/item-carousel.component";
import CustomSkeleton from "../../../components/utils/skeleton.component";
import Spacer from "../../../components/utils/spacer.component";
import { subscriptionPlanSelector } from "../../../services/subscription/plan/subscription-plan-slice.service";
import SubscriptionPlanCard from "./subscription-plan-card.component";
import SubscriptionPlanModal from "./subscription-plan-modal.component";

const SectionText = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size18,
  fontWeight: "bold",
}));

function SubscriptionSection() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { getSubscriptionPlansObj } = useSelector(subscriptionPlanSelector);
  const [showPlanModal, setShowPlanModal] = useState(false);

  const renderLoader = () => {
    const loaders = [];
    for (let i = 0; i < 3; i += 1) {
      loaders.push(
        <Grid item xs={isMobile ? 12 : 4} key={i} position="relative">
          <CustomSkeleton width="100%" height="135px" />
        </Grid>,
      );
    }
    return loaders;
  };

  return (
    <Grid item xs={12}>
      <Grid container display="flex" justifyContent="space-between">
        <Grid item display="flex" alignItems="center">
          <SectionText>Subscription Plan</SectionText>
        </Grid>
        <Grid item>
          <SubscriptionPlanModal showModal={showPlanModal} setShowModal={setShowPlanModal} />
          <CustomButton
            variant="outlined"
            fontSize={theme.fonts.fontSizes.size14}
            width="100%"
            onClick={() => setShowPlanModal(true)}
          >
            See All
          </CustomButton>
        </Grid>
      </Grid>
      <Spacer size="m" />
      {!isMobile ? (
        <Grid item xs={12} justifyContent="center">
          {getSubscriptionPlansObj.status === "succeeded" ? (
            <ItemCarousel slidesToScroll={3} slidesToShow={3}>
              {getSubscriptionPlansObj.data.items.map((item) => (
                <Box key={item.id}>
                  <SubscriptionPlanCard itemDetails={item} />
                </Box>
              ))}
            </ItemCarousel>
          ) : (
            <Grid container item columnSpacing={1} rowSpacing={1.5}>
              {renderLoader()}
            </Grid>
          )}
        </Grid>
      ) : (
        <Grid container item xs={12} justifyContent="center">
          {getSubscriptionPlansObj.status === "succeeded" ? (
            <Grid container item columnSpacing={1} rowSpacing={1.5}>
              {getSubscriptionPlansObj.data.items.slice(0, 3).map((item) => (
                <Grid item xs={12} key={item.id} position="relative">
                  <SubscriptionPlanCard itemDetails={item} />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Grid container item columnSpacing={1} rowSpacing={1.5}>
              {renderLoader()}
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
}

export default SubscriptionSection;
