import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { postReq, setAxiosToken } from "../api.services";

export const login = createAsyncThunk("auth/login", async (payload) => {
  const response = await postReq(`${process.env.REACT_APP_API_PRIVATE_V2}/login`, payload);
  return response;
});

export const register = createAsyncThunk("auth/register", async (payload) => {
  const response = await postReq(`${process.env.REACT_APP_API_PRIVATE_V2}/register`, payload);
  return response;
});

export const emailVerification = createAsyncThunk("auth/email_verification", async (payload) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/email_verification`,
    payload,
  );
  return response;
});

export const phoneNumberVerification = createAsyncThunk(
  "auth/phone_number_verification",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/verify_phone_number`,
      payload,
    );
    return response;
  },
);

export const resendVerificationCodeEmail = createAsyncThunk(
  "auth/resend_verification_code_email",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/resent_verification_code`,
      payload,
    );
    return response;
  },
);

export const smsVerificationCode = createAsyncThunk(
  "auth/sms_tac_verification",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/sms_tac_verification`,
      payload,
    );
    return response;
  },
);

export const smsTacVerification = createAsyncThunk("auth/sms_tac_verification", async (payload) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/sms_tac_verification`,
    payload,
  );
  return response;
});

export const forgotPassword = createAsyncThunk("auth/forgot_password", async (payload) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V2}/forgot_password`,
    payload,
  );
  return response;
});

export const resetPassword = createAsyncThunk("auth/reset_password", async (payload) => {
  const response = await postReq(`${process.env.REACT_APP_API_PRIVATE_V2}/reset_password`, payload);
  return response;
});

export const logout = createAsyncThunk("auth/logout", async (payload) => {
  const response = await postReq(`${process.env.REACT_APP_API_PRIVATE_V1}/logout`, payload);
  return response;
});

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    loginObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    registerObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    forgotPasswordObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    emailVerificationObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    phoneNumberVerificationObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    resendVerificationCodeEmailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    smsVerificationCodeObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    smsTacVerificationObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    resetPasswordObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    logoutObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {
    resetUser: (state) => {
      state.user = null;
      state.loginObj.data = null;
      setAxiosToken(null);
      localStorage.removeItem("katchUserAuthToken");
      localStorage.removeItem("katchUserExpireAt");
    },
  },
  extraReducers: {
    [login.pending]: (state) => {
      state.loginObj.status = "pending";
    },
    [login.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.loginObj.status = "succeeded";
      state.loginObj.data = data;
      state.user = data;
      localStorage.setItem("katchUserAuthToken", data.auth.token);
      localStorage.setItem("katchUserExpireAt", data.auth.expireAt);
      setAxiosToken(data.auth.token);
      state.loginObj.successMessage = message;
    },
    [login.rejected]: (state, action) => {
      const { message } = action.error;

      localStorage.removeItem("katchUserAuthToken");
      localStorage.removeItem("katchUserExpireAt");
      state.loginObj.status = "failed";
      state.loginObj.errorMessage = message;
    },
    [register.pending]: (state) => {
      state.registerObj.status = "pending";
    },
    [register.fulfilled]: (state, action) => {
      const { data, message } = action.payload;
      localStorage.setItem("katchUserAuthToken", data.auth.token);
      localStorage.setItem("katchUserExpireAt", data.auth.expireAt);
      setAxiosToken(data.auth.token);
      state.registerObj.status = "succeeded";
      state.registerObj.data = data;
      state.registerObj.successMessage = message;
    },
    [register.rejected]: (state, action) => {
      const { message } = action.error;

      localStorage.removeItem("katchUserAuthToken");
      localStorage.removeItem("katchUserExpireAt");
      state.registerObj.status = "failed";
      state.registerObj.errorMessage = message;
    },
    [emailVerification.pending]: (state) => {
      state.emailVerificationObj.status = "pending";
    },
    [emailVerification.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.emailVerificationObj.status = "succeeded";
      state.emailVerificationObj.data = data;
      state.emailVerificationObj.successMessage = message;
    },
    [emailVerification.rejected]: (state, action) => {
      const { message } = action.error;

      state.emailVerificationObj.status = "failed";
      state.emailVerificationObj.errorMessage = message;
    },
    [phoneNumberVerification.pending]: (state) => {
      state.phoneNumberVerificationObj.status = "pending";
    },
    [phoneNumberVerification.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.phoneNumberVerificationObj.status = "succeeded";
      state.phoneNumberVerificationObj.data = data;
      state.phoneNumberVerificationObj.successMessage = message;
    },
    [phoneNumberVerification.rejected]: (state, action) => {
      const { message } = action.error;

      state.phoneNumberVerificationObj.status = "failed";
      state.phoneNumberVerificationObj.errorMessage = message;
    },
    [resendVerificationCodeEmail.pending]: (state) => {
      state.resendVerificationCodeEmailObj.status = "pending";
    },
    [resendVerificationCodeEmail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.resendVerificationCodeEmailObj.status = "succeeded";
      state.resendVerificationCodeEmailObj.data = data;
      state.resendVerificationCodeEmailObj.successMessage = message;
    },
    [resendVerificationCodeEmail.rejected]: (state, action) => {
      const { message } = action.error;

      state.resendVerificationCodeEmailObj.status = "failed";
      state.resendVerificationCodeEmailObj.errorMessage = message;
    },
    [smsVerificationCode.pending]: (state) => {
      state.smsVerificationCodeObj.status = "pending";
    },
    [smsVerificationCode.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.smsVerificationCodeObj.status = "succeeded";
      state.smsVerificationCodeObj.data = data;
      state.smsVerificationCodeObj.successMessage = message;
    },
    [smsVerificationCode.rejected]: (state, action) => {
      const { message } = action.error;

      state.smsVerificationCodeObj.status = "failed";
      state.smsVerificationCodeObj.errorMessage = message;
    },
    [smsTacVerification.pending]: (state) => {
      state.smsTacVerificationObj.status = "pending";
    },
    [smsTacVerification.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.smsTacVerificationObj.status = "succeeded";
      state.smsTacVerificationObj.data = data;
      state.smsTacVerificationObj.successMessage = message;
    },
    [smsTacVerification.rejected]: (state, action) => {
      const { message } = action.error;

      state.smsTacVerificationObj.status = "failed";
      state.smsTacVerificationObj.errorMessage = message;
    },
    [forgotPassword.pending]: (state) => {
      state.forgotPasswordObj.status = "pending";
    },
    [forgotPassword.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.forgotPasswordObj.status = "succeeded";
      state.forgotPasswordObj.data = data;
      state.forgotPasswordObj.successMessage = message;
    },
    [forgotPassword.rejected]: (state, action) => {
      const { message } = action.error;

      state.resetPasswordObj.status = "failed";
      state.resetPasswordObj.errorMessage = message;
    },
    [resetPassword.pending]: (state) => {
      state.resetPasswordObj.status = "pending";
    },
    [resetPassword.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.resetPasswordObj.status = "succeeded";
      state.resetPasswordObj.data = data;
      state.resetPasswordObj.successMessage = message;
    },
    [resetPassword.rejected]: (state, action) => {
      const { message } = action.error;

      state.resetPasswordObj.status = "failed";
      state.resetPasswordObj.errorMessage = message;
    },
    [logout.pending]: (state) => {
      state.logoutObj.status = "pending";
    },
    [logout.fulfilled]: (state, action) => {
      const { message } = action.payload;

      state.logoutObj.status = "succeeded";
      state.logoutObj.successMessage = message;

      state.user = null;
      state.loginObj.data = null;
      setAxiosToken(null);
      localStorage.removeItem("katchUserAuthToken");
      localStorage.removeItem("katchUserExpireAt");
    },
    [logout.rejected]: (state, action) => {
      const { message } = action.error;

      state.logoutObj.status = "failed";
      state.logoutObj.errorMessage = message;
    },
  },
});

export default authSlice.reducer;

// state
export const authSelector = (state) => state.auth;

export const { resetUser } = authSlice.actions;
