import { Box, Divider, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import CustomButton from "../../../../components/button/custom-button.component";
import FormFieldTextWithIcon from "../../../../components/forms/form-field-text-with-icon.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import Form from "../../../../components/forms/form.component";
import routes from "../../../../components/navigation/routes";
import Loading from "../../../../components/notification/backdrop-loading.component";
import DialogBox from "../../../../components/notification/dialog-box.component";
import InsufficientCreditModal from "../../../../components/notification/insufficient-credit-modal.component";
import { SnackbarContext } from "../../../../components/notification/snackbar.context";
import UnverifiedUserModal from "../../../../components/notification/unverified-user-modal.component";
import PaddedView from "../../../../components/utils/padded-view.component";
import Spacer from "../../../../components/utils/spacer.component";
import { isLogin } from "../../../../infrastructure/utils";
import { getProfileDetail } from "../../../../services/profile/profile-slice.service";
import {
  getSubscriptionPlanDetails,
  subscriptionPlanSelector,
} from "../../../../services/subscription/plan/subscription-plan-slice.service";
import {
  createSubscription,
  previewSubscription,
  subscriptionSelector,
} from "../../../../services/subscription/subscription-slice.service";
import PaymentMethodCheckbox from "../components/payment-method-checkbox.component";
import PreviewSummarySubscriptionLoader from "../loader/preview-summary-subscription-loader.component";

const TitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size22,
  fontWeight: "bold",
}));

const SectionTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size18,
  fontWeight: "bold",
}));

const BodyTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size16,
}));

const SectionContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.colors.bg.primary,
  borderRadius: `${theme.shape.borderRadius[2]}px`,
}));

const validationSchema = Yup.object().shape({
  paymentMethod: Yup.string().required().label("Payment Method"),
});

function PreviewSummarySubscriptionScreen() {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const { previewSubscriptionObj } = useSelector(subscriptionSelector);
  const { getSubscriptionPlanDetailsObj } = useSelector(subscriptionPlanSelector);
  const createSnackBar = useContext(SnackbarContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const formRef = useRef();
  const promoCodeFormRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [isPaymentStage, setIsPaymentStage] = useState(false);
  const [redeemSuccess, setRedeemSuccess] = useState(false);
  const [showInsufficientCreditModal, setShowInsufficientCreditModal] = useState(false);
  const [showRecurrentConfirmationModal, setShowRecurrentConfirmationModal] = useState(false);

  const previewSubscriptionPlan = (values, promoRedeem) => {
    if (promoRedeem) {
      setIsLoading(true);
    }

    dispatch(previewSubscription(values)).then(({ meta, error }) => {
      setIsLoading(false);

      if (meta.requestStatus === "fulfilled") {
        formRef.current.setFieldValue("promoCode", values.promoCode);
        setRedeemSuccess(true);
      }

      if (meta.requestStatus === "rejected") {
        formRef.current.setFieldValue("promoCode", "");
        promoCodeFormRef.current.setFieldValue("promoCode", "");
        setRedeemSuccess(false);
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleFormSubmission = (values) => {
    const payloadValues = { ...values };
    if (values.paymentMethod.includes("tokenized_payment")) {
      const [method, tokenizedRemoteCustomerId] = values.paymentMethod.split(":");
      payloadValues.paymentMethod = method;
      payloadValues.tokenizedRemoteCustomerId = tokenizedRemoteCustomerId;
    }
    setIsLoading(true);
    dispatch(createSubscription(payloadValues)).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        if (
          values.paymentMethod === "credits" ||
          payload.data.paymentTransaction.status === "paid"
        ) {
          dispatch(getProfileDetail());
          navigate(routes.SUBSCRIPTIONPLANPAYMENTSUCCESS, { state: { ...payload.data } });
          createSnackBar({
            message: payload.message,
            type: "success",
            open: true,
          });
        } else {
          window.open(payload.data.bill.remoteUrl, "_self");
        }
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const clearPromoForm = () => {
    formRef.current.setFieldValue("promoCode", "");
    promoCodeFormRef.current.setFieldValue("promoCode", "");
    previewSubscriptionPlan({ ...formRef.current.values, promoCode: "" }, true);
  };

  const handleSubmit = (values) => {
    if (values.paymentMethod === "credits" && !previewSubscriptionObj.data.creditPurchasable) {
      setShowInsufficientCreditModal(true);
      return;
    }

    if (values.paymentMethod.includes("tokenized_payment")) {
      setShowRecurrentConfirmationModal(true);
      return;
    }

    handleFormSubmission(values);
  };

  useEffect(() => {
    dispatch(
      getSubscriptionPlanDetails({
        planId: params.get("planId"),
      }),
    ).then(({ meta, error }) => {
      if (meta.requestStatus === "fulfilled") {
        if (isLogin()) {
          dispatch(
            previewSubscription({
              planId: params.get("planId"),
            }),
          );
        }
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  return (
    <Box>
      {getSubscriptionPlanDetailsObj.status === "succeeded" &&
        getSubscriptionPlanDetailsObj.data.requireVerification && (
          <UnverifiedUserModal cancelAction={() => navigate(-1)} />
        )}

      {previewSubscriptionObj.status === "succeeded" && (
        <InsufficientCreditModal
          isShow={showInsufficientCreditModal}
          setIsShowAlert={setShowInsufficientCreditModal}
        />
      )}
      {previewSubscriptionObj.status === "succeeded" &&
        previewSubscriptionObj.data.subscription.plan.type === "recurrent" && (
          <DialogBox
            title="Confirm"
            isShow={showRecurrentConfirmationModal}
            setIsShowAlert={setShowRecurrentConfirmationModal}
            message={
              <Grid item>
                <Typography sx={{ textAlign: "justify" }}>
                  The subsequent payments will automatically be charged when your subscription
                  expires and will be activated immediately.
                </Typography>
              </Grid>
            }
            isConfirmHandle={() => {
              setShowRecurrentConfirmationModal(false);
              handleFormSubmission(formRef.current.values);
            }}
            buttonText="Ok"
          />
        )}
      <Loading isLoading={isLoading} />
      <Spacer size="l" />
      <PaddedView multiples={2}>
        <TitleTypography>Subscription</TitleTypography>
        <Spacer size="l" />
        {getSubscriptionPlanDetailsObj.status === "succeeded" ||
        previewSubscriptionObj.status === "succeeded" ? (
          <Form
            innerRef={formRef}
            initialValues={{
              planId: params.get("planId"),
              paymentMethod: "",
              promoCode: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Grid container columnSpacing={2} rowSpacing={2}>
              <Grid container item xs={isMobile ? 12 : 7} flexDirection="column" rowSpacing={2}>
                <Grid item>
                  <SectionContainer item>
                    <PaddedView multiples={isMobile ? 3 : 4}>
                      <Grid
                        item
                        display="flex"
                        alignContent="center"
                        justifyContent="space-between"
                      >
                        <Grid item>
                          <SectionTypography>Subscription Plan Details</SectionTypography>
                        </Grid>
                        {!isPaymentStage && !isMobile && (
                          <Grid item>
                            <CustomButton
                              fontSize={theme.fonts.fontSizes.size16}
                              isLoading={previewSubscriptionObj.status === "pending"}
                              fontPadding="3px"
                              onClick={() => {
                                if (!isLogin()) {
                                  navigate(routes.LOGIN);
                                } else {
                                  setIsPaymentStage(true);
                                }
                              }}
                            >
                              Get this plan
                            </CustomButton>
                          </Grid>
                        )}
                      </Grid>
                      <Spacer size="m" />

                      <Grid container columnSpacing={2} alignContent="center">
                        <Grid
                          item
                          alignSelf="center"
                          paddingY="5px"
                          alignItems="center"
                          display="flex"
                          xs={4}
                        >
                          <img
                            src={getSubscriptionPlanDetailsObj.data?.imagePath}
                            alt="planImage"
                            width="100%"
                            height={isMobile ? 90 : 150}
                            style={{
                              borderRadius: `${theme.shape.borderRadius[2]}px`,
                              objectFit: "cover",
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          display="grid"
                          flexDirection="column"
                          alignContent="space-between"
                          marginY="10px"
                          xs={8}
                        >
                          <Grid item>
                            <Grid item>
                              <Typography fontWeight="bold" className="planTitle">
                                {getSubscriptionPlanDetailsObj.data?.title}
                              </Typography>
                            </Grid>
                            <Spacer />
                            <Grid item display="flex" alignContent="center">
                              <Typography>
                                {getSubscriptionPlanDetailsObj.data?.shortDescription}
                              </Typography>
                            </Grid>
                            <Spacer />
                            <Grid item display="flex" alignContent="center">
                              {getSubscriptionPlanDetailsObj.data?.strikePrice && (
                                <Typography
                                  color={theme.palette.colors.text.secondary}
                                  sx={{ textDecoration: "line-through" }}
                                >
                                  RM {getSubscriptionPlanDetailsObj.data?.strikePrice}
                                </Typography>
                              )}

                              <Spacer size="xs" position="left" />
                              <Typography fontWeight="bold" color="primary">
                                RM {getSubscriptionPlanDetailsObj.data?.price}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item display="flex" alignContent="center">
                            <Typography fontWeight="600">
                              {getSubscriptionPlanDetailsObj.data &&
                                `As low as RM ${getSubscriptionPlanDetailsObj.data.pricePerDay} / Day`}{" "}
                            </Typography>
                          </Grid>
                        </Grid>
                        {!isPaymentStage && isMobile && (
                          <Grid item xs={12}>
                            <Spacer />
                            <CustomButton
                              fontSize={theme.fonts.fontSizes.size16}
                              isLoading={previewSubscriptionObj.status === "pending"}
                              fontPadding="3px"
                              onClick={() => {
                                if (!isLogin()) {
                                  navigate(routes.LOGIN);
                                } else {
                                  setIsPaymentStage(true);
                                }
                              }}
                            >
                              Get this plan
                            </CustomButton>
                          </Grid>
                        )}
                      </Grid>
                    </PaddedView>
                  </SectionContainer>
                </Grid>

                {isPaymentStage && (
                  <>
                    <Grid item>
                      <SectionContainer item>
                        <PaddedView multiples={isMobile ? 3 : 4}>
                          <SectionTypography>Promos & Vouchers</SectionTypography>
                          <Spacer size="m" />

                          <Form
                            innerRef={promoCodeFormRef}
                            initialValues={{
                              promoCode: "",
                            }}
                            validationSchema={Yup.object().shape({
                              promoCode: Yup.string().required().label("Promo Code"),
                            })}
                            onSubmit={(values) => {
                              previewSubscriptionPlan(
                                {
                                  ...formRef.current.values,
                                  ...values,
                                },
                                true,
                              );
                            }}
                          >
                            <Grid container columnSpacing={2}>
                              <Grid item xs={8}>
                                <FormFieldTextWithIcon
                                  name="promoCode"
                                  placeholder="Promo Code"
                                  toUpper={true}
                                  clearPromoForm={clearPromoForm}
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <FormSubmitButton width="100%" borderRadius={3}>
                                  <Typography>Redeem</Typography>
                                </FormSubmitButton>
                              </Grid>
                            </Grid>
                          </Form>
                        </PaddedView>
                      </SectionContainer>
                    </Grid>

                    <Grid item>
                      <SectionContainer item>
                        <PaddedView multiples={isMobile ? 3 : 4}>
                          <Grid
                            container
                            justifyContent="space-between"
                            display="flex"
                            alignItems="center"
                          >
                            <Grid item>
                              <SectionTypography>Payment Method</SectionTypography>
                            </Grid>
                            {previewSubscriptionObj.status === "succeeded" &&
                              previewSubscriptionObj.data.subscription.plan.type ===
                                "recurrent" && (
                                <Grid item>
                                  <CustomButton
                                    variant="outlined"
                                    fontSize="16px"
                                    onClick={() => navigate(routes.PAYMENT_METHODS)}
                                  >
                                    Add Card
                                  </CustomButton>
                                </Grid>
                              )}
                          </Grid>
                          <Spacer size="m" />
                          <PaymentMethodCheckbox />
                        </PaddedView>
                      </SectionContainer>
                    </Grid>
                  </>
                )}
              </Grid>

              <Grid item xs={isMobile ? 12 : 5}>
                <SectionContainer item>
                  {!isPaymentStage ? (
                    <PaddedView multiples={isMobile ? 3 : 4}>
                      <SectionTypography>What&apos;s in Your Plan</SectionTypography>
                      <Spacer size="m" />
                      <Typography textAlign="justify" whiteSpace="pre-wrap">
                        {getSubscriptionPlanDetailsObj.data?.description}
                      </Typography>
                    </PaddedView>
                  ) : (
                    <PaddedView multiples={isMobile ? 3 : 4}>
                      <TitleTypography>Payment Summary</TitleTypography>
                      <Spacer size="m" />
                      <Grid
                        item
                        display="grid"
                        flexDirection="column"
                        alignContent="space-between"
                        minHeight="220px"
                      >
                        <Grid
                          container
                          item
                          dispaly="flex"
                          justifyContent="space-between"
                          rowSpacing={1}
                        >
                          <Grid item xs={6}>
                            <BodyTypography>Subtotal</BodyTypography>
                          </Grid>
                          <Grid item xs={6} textAlign="right">
                            <BodyTypography>
                              RM {previewSubscriptionObj.data?.paymentSummary.subtotal}
                            </BodyTypography>
                          </Grid>
                          {previewSubscriptionObj.data?.paymentSummary.additionalFees.length !==
                            0 &&
                            previewSubscriptionObj.data?.paymentSummary.additionalFees.map(
                              (item) => (
                                <Grid item container xs={12} key={uuidv4()}>
                                  <Grid item xs={6}>
                                    <BodyTypography>{item.description}</BodyTypography>
                                  </Grid>
                                  <Grid item xs={6} textAlign="right">
                                    <BodyTypography>RM {item.price}</BodyTypography>
                                  </Grid>
                                </Grid>
                              ),
                            )}
                          {previewSubscriptionObj.data?.paymentSummary.voucherDiscount !== "0.00" &&
                            redeemSuccess && (
                              <>
                                <Grid item xs={6}>
                                  <BodyTypography>Voucher Discount</BodyTypography>
                                </Grid>
                                <Grid item xs={6} textAlign="right">
                                  <BodyTypography>
                                    -RM{" "}
                                    {previewSubscriptionObj.data?.paymentSummary.voucherDiscount}
                                  </BodyTypography>
                                </Grid>
                              </>
                            )}
                        </Grid>
                        <Grid item>
                          <Grid item>
                            <Spacer size="l" />
                            <Divider />
                            <Spacer size="l" />
                          </Grid>
                          <Grid
                            container
                            item
                            dispaly="flex"
                            justifyContent="space-between"
                            rowSpacing={1}
                          >
                            <SectionTypography>Total Price</SectionTypography>
                            <SectionTypography>
                              RM {previewSubscriptionObj.data.paymentSummary.totalPrice}
                            </SectionTypography>
                          </Grid>
                          <Spacer size="xl" />
                          <Grid item>
                            <FormSubmitButton width="100%" borderRadius={3}>
                              Confirm
                            </FormSubmitButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    </PaddedView>
                  )}
                </SectionContainer>
                {isPaymentStage &&
                  previewSubscriptionObj.status === "succeeded" &&
                  previewSubscriptionObj.data.paymentSummary.subsequentPayment && (
                    <>
                      <Spacer size="m" />
                      <SectionContainer item>
                        <PaddedView multiples={isMobile ? 3 : 4}>
                          <SectionTypography>Subsequent Payments</SectionTypography>
                          <Spacer size="m" />
                          <Grid
                            container
                            item
                            dispaly="flex"
                            justifyContent="space-between"
                            rowSpacing={1}
                          >
                            <Grid item xs={6}>
                              <SectionTypography>Payment Date</SectionTypography>
                            </Grid>
                            <Grid item xs={6} textAlign="right">
                              <SectionTypography>Total Price</SectionTypography>
                            </Grid>
                            {previewSubscriptionObj.data.paymentSummary.subsequentPayment.dates.map(
                              (item) => (
                                <Grid
                                  item
                                  container
                                  xs={12}
                                  justifyContent="space-between"
                                  key={item}
                                >
                                  <Grid item xs={6}>
                                    <BodyTypography>{item}</BodyTypography>
                                  </Grid>
                                  <Grid item xs={6} textAlign="right">
                                    <BodyTypography>
                                      RM{" "}
                                      {
                                        previewSubscriptionObj.data.paymentSummary.subsequentPayment
                                          .price
                                      }
                                    </BodyTypography>
                                  </Grid>
                                </Grid>
                              ),
                            )}
                          </Grid>
                        </PaddedView>
                      </SectionContainer>
                    </>
                  )}
                {previewSubscriptionObj.status === "succeeded" &&
                  previewSubscriptionObj.data.subscription.plan.type !== "recurrent" &&
                  getSubscriptionPlanDetailsObj.status === "succeeded" &&
                  getSubscriptionPlanDetailsObj.data.expireNoDaysFromPurchaseDate && (
                    <>
                      <Spacer size="m" />
                      <SectionContainer item>
                        <PaddedView multiples={isMobile ? 3 : 4}>
                          <SectionTypography>Important Info</SectionTypography>
                          <Spacer size="m" />
                          <Typography textAlign="justify" whiteSpace="pre-wrap">
                            Upon purchase, please do activate this plan within{" "}
                            {getSubscriptionPlanDetailsObj.data.expireNoDaysFromPurchaseDate} days.
                          </Typography>
                        </PaddedView>
                      </SectionContainer>
                    </>
                  )}
              </Grid>
            </Grid>
          </Form>
        ) : (
          <PreviewSummarySubscriptionLoader />
        )}
      </PaddedView>
    </Box>
  );
}

export default PreviewSummarySubscriptionScreen;
