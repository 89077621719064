import { Box, Grid, Modal, styled, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import PaddedView from "../../../components/utils/padded-view.component";
import CustomSkeleton from "../../../components/utils/skeleton.component";
import Spacer from "../../../components/utils/spacer.component";
import TopTitleBox from "../../../components/utils/top-title-box.component";
import { packagePlanSelector } from "../../../services/package/plan/package-plan-slice.service";
import PackagePlanCard from "./package-plan-card.component";

const ModalBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  position: "absolute",
  top: !isMobile && "50%",
  left: !isMobile && "50%",
  transform: !isMobile && "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.white,
  borderRadius: !isMobile && theme.shape.borderRadius[2],
  boxShadow: 24,
  maxWidth: !isMobile && "550px",
  width: isMobile ? "100%" : "90%",
  height: isMobile ? "100%" : "600px",
  outline: "none",
  overflowY: isMobile ? "scroll" : "visible",
}));

function PackagePlanModal({ businessId, showModal, setShowModal }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { getPackagePlansObj } = useSelector(packagePlanSelector);

  const renderLoader = () => {
    const loaders = [];
    for (let i = 0; i < 3; i += 1) {
      loaders.push(
        <Grid item xs={12} key={i} position="relative">
          <CustomSkeleton width="100%" height="150px" />
        </Grid>,
      );
    }
    return loaders;
  };

  return (
    <Modal open={showModal} onClose={() => setShowModal(false)}>
      <ModalBox isMobile={isMobile}>
        <PaddedView multiples={2}>
          <Box
            sx={{
              overflowY: !isMobile && "auto",
              height: !isMobile && "550px",
            }}
          >
            <TopTitleBox title="Packages" requireCloseButton={true} setShowModal={setShowModal} />
            <Spacer position="top" size="l" />
            <Grid container item xs={12} justifyContent="center" rowSpacing={3} padding="10px">
              {getPackagePlansObj.status === "succeeded" ? (
                <Grid container item columnSpacing={1} rowSpacing={1.5}>
                  {getPackagePlansObj.data.items.map((item) => (
                    <Grid item xs={12} key={item.id} position="relative">
                      <PackagePlanCard businessId={businessId} itemDetails={item} />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Grid container item columnSpacing={1} rowSpacing={1.5}>
                  {renderLoader()}
                </Grid>
              )}
            </Grid>
          </Box>
        </PaddedView>
      </ModalBox>
    </Modal>
  );
}

PackagePlanModal.propTypes = {
  businessId: PropTypes.number.isRequired,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};

export default PackagePlanModal;
