import FeedbackIcon from "@mui/icons-material/Feedback";
import { Box, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";
import { updateNotificationIndex } from "../../../services/notification/notification-slice.service";
import Spacer from "../../../components/utils/spacer.component";

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size15,
  color: theme.palette.colors.text.primary,
  textAlign: "left",
}));

const NotificationDot = styled(Box)(({ theme }) => ({
  position: "absolute",
  height: "10px",
  width: "10px",
  border: "1px solid #fff",
  borderRadius: theme.shape.borderRadius[1],
  zIndex: 1,
  top: -2,
  left: 17,

  justifyContent: "center",
  alignItems: "center",
  display: "flex",
}));

function NotificationList({
  notifications,
  setNotificationContent,
  setShowDetailsModal,
  setShowNotification,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();

  const updateNotifications = (id) => {
    const updatedDataList = notifications.map((data) => {
      if (data.id === id) {
        return { ...data, read: true };
      }
      return data;
    });
    dispatch(updateNotificationIndex({ updatedNotificationIndex: updatedDataList }));
  };

  return (
    <Box
      sx={{
        overflowY: "auto",
        minHeight: !isMobile && "80px",
        maxHeight: !isMobile && "300px",
        height: isMobile && "78vh",
        padding: "10px",
      }}
    >
      <Grid container rowSpacing={2}>
        {notifications.length > 0 ? (
          notifications.map((item) => (
            <Grid
              item
              container
              sx={{
                cursor: "pointer",
                "&:hover": { backgroundColor: theme.palette.colors.bg.primary },
                opacity: item.read ? 0.7 : 1,
              }}
              onClick={() => {
                setNotificationContent(item);
                setShowDetailsModal(true);
                updateNotifications(item.id);
                if (!isMobile) {
                  setShowNotification(false);
                }
              }}
              key={item.id}
            >
              <Grid item xs={1} alignSelf="center" position="relative">
                <FeedbackIcon color={item.read ? "grey" : "primary"} />
                {!item.read && (
                  <NotificationDot sx={{ backgroundColor: theme.palette.colors.ui.notification }} />
                )}
              </Grid>
              <Grid item xs={0.5}>
                <Spacer position="left" />
              </Grid>
              <Grid item xs={8}>
                <StyledTypography sx={{ fontWeight: theme.fonts.fontWeights.bold }} noWrap={true}>
                  {item.header}
                </StyledTypography>
                <StyledTypography noWrap={true}>{item.subheader}</StyledTypography>
              </Grid>
              <Grid item xs={0.5}>
                <Spacer position="left" />
              </Grid>
              <Grid item xs={2}>
                <StyledTypography>{item.dateNotified}</StyledTypography>
              </Grid>
            </Grid>
          ))
        ) : (
          <Grid item xs={12} marginTop="20px">
            <Typography textAlign="center" sx={{ color: theme.palette.colors.text.primary }}>
              No notifications
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

NotificationList.propTypes = {
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      header: PropTypes.string,
      subheader: PropTypes.string,
      content: PropTypes.string,
      type: PropTypes.string,
      read: PropTypes.bool,
      dateNotified: PropTypes.string,
    }),
  ).isRequired,
  setNotificationContent: PropTypes.func.isRequired,
  setShowDetailsModal: PropTypes.func.isRequired,
  setShowNotification: PropTypes.func.isRequired,
};

export default NotificationList;
