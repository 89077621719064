import React from "react";
import * as Yup from "yup";
import { Box, Grid, IconButton, Modal, styled, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import CallIcon from "@mui/icons-material/Call";
import FormFieldTextWithIcon from "../../../components/forms/form-field-text-with-icon.component";
import Form from "../../../components/forms/form.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";

const validationSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .when({
      is: (value) => value?.length,
      then: (rule) => rule.min(10),
    })
    .required()
    .label("Phone Number"),
});

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.brand.white,
  borderRadius: theme.shape.borderRadius[2],
  boxShadow: 24,
  padding: "50px",
  width: "90%",
  maxWidth: "450px",
  outline: "none",
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  height: "25px",
  width: "25px",
  color: theme.palette.colors.text.primary,
  strokeWidth: 2,
  stroke: theme.palette.colors.text.primary,
}));

function EditPhoneNumberModal({
  showModal,
  setShowModal,
  onSubmit,
  message,
  buttonText,
  title,
  isDisabled,
  prevPhoneNum,
}) {
  const theme = useTheme();

  return (
    <Modal open={showModal} onClose={() => setShowModal(false)}>
      <ModalBox>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <CloseIconButton onClick={() => setShowModal(false)}>
            <CloseIcon />
          </CloseIconButton>
        </Box>

        <Grid container spacing={4} sx={{ textAlign: "center" }}>
          {title && (
            <Grid item xs={12}>
              <Typography variant="h5">{title}</Typography>
            </Grid>
          )}

          {message && (
            <Grid item xs={12}>
              <Typography sx={{ color: theme.palette.colors.text.secondary }} textAlign="center">
                {message}
              </Typography>
            </Grid>
          )}

          <Form
            initialValues={{ phoneNumber: prevPhoneNum }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            <Grid item xs={12}>
              <FormFieldTextWithIcon
                name="phoneNumber"
                placeholder="Phone number"
                showIcon={true}
                icon={<CallIcon color="primary" />}
              />
            </Grid>
            <Grid item xs={12}>
              <FormSubmitButton isLoading={isDisabled} width="60%" borderRadius={1}>
                <Typography>{buttonText}</Typography>
              </FormSubmitButton>
            </Grid>
          </Form>
        </Grid>
      </ModalBox>
    </Modal>
  );
}

EditPhoneNumberModal.defaultProps = {
  title: null,
  message: null,
  buttonText: "Confirm",
  isDisabled: false,
  prevPhoneNum: null,
};

EditPhoneNumberModal.propTypes = {
  isDisabled: PropTypes.bool,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  message: PropTypes.string,
  buttonText: PropTypes.string,
  title: PropTypes.string,
  prevPhoneNum: PropTypes.string,
};

export default EditPhoneNumberModal;
