import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postReq } from "../api.services";

export const previewPackage = createAsyncThunk("packages/preview", async (payload) => {
  const { planId, promoCode } = payload;
  const response = await postReq(`${process.env.REACT_APP_API_PRIVATE_V1}/packages/preview`, {
    planId,
    promoCode,
  });
  return response;
});

export const createPackage = createAsyncThunk("packages/create", async (payload) => {
  const { planId, paymentMethod, promoCode } = payload;
  const response = await postReq(`${process.env.REACT_APP_API_PRIVATE_V1}/packages`, {
    planId,
    paymentMethod,
    promoCode,
    platform: "web",
  });
  return response;
});

const packageSlice = createSlice({
  name: "package",
  initialState: {
    previewPackageObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createPackageObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  extraReducers: {
    [previewPackage.pending]: (state) => {
      state.previewPackageObj.status = "pending";
    },
    [previewPackage.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.previewPackageObj.status = "succeeded";
      state.previewPackageObj.data = data;
      state.previewPackageObj.successMessage = message;
    },
    [previewPackage.rejected]: (state, action) => {
      const { message } = action.error;

      state.previewPackageObj.status = "failed";
      state.previewPackageObj.errorMessage = message;
    },
    [createPackage.pending]: (state) => {
      state.createPackageObj.status = "pending";
    },
    [createPackage.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createPackageObj.status = "succeeded";
      state.createPackageObj.data = data;
      state.createPackageObj.successMessage = message;
    },
    [createPackage.rejected]: (state, action) => {
      const { message } = action.error;

      state.createPackageObj.status = "failed";
      state.createPackageObj.errorMessage = message;
    },
  },
});

export default packageSlice.reducer;

// state
export const packageSelector = (state) => state.package;
