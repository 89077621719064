import { Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { format } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import ItemCarousel from "../../../components/utils/item-carousel.component";
import CustomSkeleton from "../../../components/utils/skeleton.component";
import Spacer from "../../../components/utils/spacer.component";
import { businessSelector } from "../../../services/business/business-slice.service";
import { getUpcomingEvents } from "../../../services/search/event/search-event-slice.service";
import UpcomingEventCard from "../../dashboard/components/upcoming-event-card.component";

const SectionText = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size18,
  fontWeight: "bold",
}));

function UpcomingEventSection() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const { getBusinessDetailsObj } = useSelector(businessSelector);
  const createSnackBar = useContext(SnackbarContext);
  const [availableSessions, setAvailableSessions] = useState(null);

  useEffect(() => {
    let endAt = new Date();
    endAt.setDate(endAt.getDate() + 7);
    endAt = format(endAt, "yyyy-MM-dd 23:30:00");

    dispatch(
      getUpcomingEvents({
        lat: 3.000031,
        long: 101.5330035,
        businessIds: getBusinessDetailsObj.data.id,
        page: 1,
      }),
    ).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        setAvailableSessions(payload.data.map((date) => date.sessions).flat(1));
      }

      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  const getSlidesToShow = () => {
    if (isMobile) {
      return 1;
    }

    if (isTablet) {
      return 2;
    }

    return 3;
  };

  const renderResult = () => {
    if (availableSessions) {
      if (availableSessions.length > 0) {
        return (
          <ItemCarousel slidesToScroll={isMobile ? 1 : 2} slidesToShow={getSlidesToShow()}>
            {availableSessions.map((item) => (
              <UpcomingEventCard
                itemDetails={item}
                containMultupleCategories={availableSessions.some(
                  (session) => session.multipleCategories === true,
                )}
              />
            ))}
          </ItemCarousel>
        );
      }

      return (
        <Grid container display="flex" justifyContent="center">
          No upcoming events.
        </Grid>
      );
    }

    return (
      <Grid item container columnSpacing={2} rowSpacing={1}>
        <Grid item xs={isMobile ? 12 : 4}>
          <CustomSkeleton width="100%" height={146} />
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <CustomSkeleton width="100%" height={146} />
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <CustomSkeleton width="100%" height={146} />
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid item xs={12}>
      <Grid container display="flex" justifyContent="space-between">
        <Grid item display="flex" alignItems="center">
          <SectionText>Upcoming Events</SectionText>
        </Grid>
      </Grid>
      <Spacer size="m" />
      <Grid item xs={12} sx={{ paddingX: "10px" }}>
        {renderResult()}
      </Grid>
    </Grid>
  );
}

export default UpcomingEventSection;
