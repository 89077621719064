import { Box, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import CustomButton from "../../../components/button/custom-button.component";
import ItemCarousel from "../../../components/utils/item-carousel.component";
import CustomSkeleton from "../../../components/utils/skeleton.component";
import Spacer from "../../../components/utils/spacer.component";
import { businessSelector } from "../../../services/business/business-slice.service";
import { packagePlanSelector } from "../../../services/package/plan/package-plan-slice.service";
import PackagePlanCard from "./package-plan-card.component";
import PackagePlanModal from "./package-plan-modal.component";

const SectionText = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size18,
  fontWeight: "bold",
}));

function PackageSection() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { getBusinessDetailsObj } = useSelector(businessSelector);
  const [showPackagePlanModal, setShowPackagePlanModal] = useState(false);
  const { getPackagePlansObj } = useSelector(packagePlanSelector);

  const renderLoader = () => {
    const loaders = [];
    for (let i = 0; i < 3; i += 1) {
      loaders.push(
        <Grid item xs={isMobile ? 12 : 4} key={i} position="relative">
          <CustomSkeleton width="100%" height="140px" />
        </Grid>,
      );
    }
    return loaders;
  };

  return (
    <Grid item xs={12}>
      <Grid container display="flex" justifyContent="space-between">
        <PackagePlanModal
          businessId={getBusinessDetailsObj.data.id}
          showModal={showPackagePlanModal}
          setShowModal={setShowPackagePlanModal}
        />
        <Grid item display="flex" alignItems="center">
          <SectionText>PT Packages</SectionText>
        </Grid>
        <Grid item>
          <CustomButton
            variant="outlined"
            fontSize={theme.fonts.fontSizes.size14}
            onClick={() => setShowPackagePlanModal(true)}
          >
            See All
          </CustomButton>
        </Grid>
      </Grid>
      <Spacer size="m" />
      {!isMobile ? (
        <Grid item xs={12} justifyContent="center">
          {getPackagePlansObj.status === "succeeded" ? (
            <ItemCarousel slidesToScroll={3} slidesToShow={3}>
              {getPackagePlansObj.data.items.map((item) => (
                <Box key={item.id}>
                  <PackagePlanCard businessId={getBusinessDetailsObj.data.id} itemDetails={item} />
                </Box>
              ))}
            </ItemCarousel>
          ) : (
            <Grid container item columnSpacing={1} rowSpacing={1.5}>
              {renderLoader()}
            </Grid>
          )}
        </Grid>
      ) : (
        <Grid container item xs={12} justifyContent="center">
          {getPackagePlansObj.status === "succeeded" ? (
            <Grid container item xs={12} columnSpacing={1} rowSpacing={1.5}>
              {getPackagePlansObj.data.items.slice(0, 3).map((item) => (
                <Grid item xs={isMobile ? 12 : 4} key={item.id} position="relative">
                  <PackagePlanCard businessId={getBusinessDetailsObj.data.id} itemDetails={item} />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Grid container item columnSpacing={1} rowSpacing={1.5}>
              {renderLoader()}
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
}

export default PackageSection;
