import {
  Box,
  Grid,
  Modal,
  Pagination,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { format } from "date-fns";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import FormPickerDate from "../../../components/forms/form-picker-date.component";
import Form from "../../../components/forms/form.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import PaddedView from "../../../components/utils/padded-view.component";
import CustomSkeleton from "../../../components/utils/skeleton.component";
import Spacer from "../../../components/utils/spacer.component";
import TopTitleBox from "../../../components/utils/top-title-box.component";
import { businessSelector } from "../../../services/business/business-slice.service";
import {
  getFitnessClasses,
  searchFitnessClassSelector,
} from "../../../services/search/fitness-class/search-fitness-class-slice.service";
import { searchSelector } from "../../../services/search/search-slice.service";
import ClassResultCard from "../../explore/components/class/class-result-card.component";

const ModalBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  position: "absolute",
  top: !isMobile && "50%",
  left: !isMobile && "50%",
  transform: !isMobile && "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.white,
  borderRadius: !isMobile && theme.shape.borderRadius[2],
  boxShadow: 24,
  maxWidth: !isMobile && "550px",
  width: isMobile ? "100%" : "90%",
  height: isMobile && "100%",
  minHeight: "550px",
  outline: "none",
  overflowY: isMobile && "auto",
}));

const validationSchema = Yup.object().shape({
  date: Yup.date().required().label("Date"),
});

function AvailableClassSessionModal({ showModal, setShowModal }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const { reduxStoreTimeIndex } = useSelector(searchSelector);
  const { getFitnessClassesObj } = useSelector(searchFitnessClassSelector);
  const { getBusinessDetailsObj } = useSelector(businessSelector);
  const createSnackBar = useContext(SnackbarContext);
  const [page, setPage] = useState(1);
  const formRef = useRef();

  const getAvailableSession = (values, newPage) => {
    let startAt = format(new Date(values.date), "yyyy-MM-dd 04:00:00");
    if (values.date === format(new Date(), "yyyy-MM-dd")) {
      const currentTime = format(new Date(), "HH:mm:ss");
      const timeOffset = reduxStoreTimeIndex.find((item) => item.time > currentTime).value;
      startAt = `${format(new Date(), "yyyy-MM-dd")} ${reduxStoreTimeIndex[timeOffset].time}`;
    }

    dispatch(
      getFitnessClasses({
        startAt,
        endAt: format(new Date(values.date), "yyyy-MM-dd 23:30:00"),
        businessesId: getBusinessDetailsObj.data.id,
        lat: parseFloat(getBusinessDetailsObj.data.location.lat),
        long: parseFloat(getBusinessDetailsObj.data.location.long),
        distance: 30,
        fitnessCategoriesId: [],
        page: newPage,
      }),
    ).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const onPageChange = (event, newPage) => {
    setPage(newPage);
    getAvailableSession(formRef.current.values, newPage);
  };

  useEffect(() => {
    if (showModal) {
      getAvailableSession({ date: format(new Date(), "yyyy-MM-dd") }, 1);
    }
  }, [showModal]);

  const renderAvailableSession = () => {
    if (getFitnessClassesObj.status === "succeeded") {
      if (getFitnessClassesObj.data.items.length > 0) {
        return getFitnessClassesObj.data.items.map((item) => (
          <Grid item key={item.id}>
            <ClassResultCard itemDetails={item} />
            <Spacer />
          </Grid>
        ));
      }

      return (
        <Grid
          item
          display="flex"
          textAlign="center"
          alignItems="center"
          sx={{
            backgroundColor: theme.palette.colors.bg.primary,
            borderRadius: `${theme.shape.borderRadius[2]}px`,
          }}
          height={isMobile ? 142 : 192}
        >
          <PaddedView multiples={4}>
            <Typography fontSize={theme.fonts.fontSizes.size20} fontWeight="bold">
              No results found.
            </Typography>
            <Typography>Please choose another date.</Typography>
          </PaddedView>
        </Grid>
      );
    }
    return (
      <Grid item>
        <Grid item>
          <CustomSkeleton width="100%" height={isMobile ? 142 : 192} />
        </Grid>
        <Spacer />
        <Grid item>
          <CustomSkeleton width="100%" height={isMobile ? 142 : 192} />
        </Grid>
      </Grid>
    );
  };

  return (
    <Modal open={showModal} onClose={() => setShowModal(false)}>
      <ModalBox isMobile={isMobile}>
        <PaddedView multiples={2}>
          <TopTitleBox
            title="Available Session"
            requireCloseButton={true}
            setShowModal={setShowModal}
          />
          <Spacer position="top" size="l" />
          <Grid container item xs={12} justifyContent="center" rowSpacing={3} padding="10px">
            <Form
              innerRef={formRef}
              initialValues={{
                date: format(new Date(), "yyyy-MM-dd"),
              }}
              validationSchema={validationSchema}
              onSubmit={getAvailableSession}
            >
              <Grid item xs={8}>
                <FormPickerDate name="date" submitOnChange={true} />
              </Grid>
            </Form>
          </Grid>
          <Spacer size="l" />

          <Grid item sx={{ overflowY: "auto", height: !isMobile && "400px" }}>
            {renderAvailableSession()}
          </Grid>
          {getFitnessClassesObj.status === "succeeded" &&
            getFitnessClassesObj.data.items.length > 0 && (
              <>
                <Spacer />
                <Grid container justifyContent="center">
                  <Pagination
                    page={page}
                    onChange={onPageChange}
                    count={getFitnessClassesObj.data.pagination.totalPages}
                    variant="outlined"
                  />
                </Grid>
              </>
            )}
        </PaddedView>
      </ModalBox>
    </Modal>
  );
}

AvailableClassSessionModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};

export default AvailableClassSessionModal;
