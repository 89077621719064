import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import WifiRoundedIcon from "@mui/icons-material/WifiRounded";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
  Typography,
} from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { fitnessClassSessionBookingSelector } from "../../../../../services/fitness_class/session/booking/fitness-class-session-booking-slice.service";
import { profileSelector } from "../../../../../services/profile/profile-slice.service";

const LabelContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  marginLeft: "5px",
});

const LabelText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  fontWeight: "lighter",
  marginLeft: "15px",
}));

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
  marginTop: 15,
  marginBottom: 15,
}));

const StyledRadio = styled(Radio)(({ theme }) => ({
  color: theme.palette.colors.brand.primary,
  "&.Mui-checked": {
    color: theme.palette.colors.brand.primary,
  },
}));

function PaymentMethodCheckbox({ setPayByPackageCode, classDetails }) {
  const { values, setFieldValue } = useFormikContext();
  const [value, setValue] = useState(values.paymentMethod);
  const { getProfileDetailObj } = useSelector(profileSelector);
  const { getFitnessClassBookingPaymentOptionObj } = useSelector(
    fitnessClassSessionBookingSelector,
  );
  const isPackageRedeemableOnly =
    classDetails.booking.status === "merchant_request" &&
    classDetails.booking.session.requestedPackage.id !== null;

  const handleChange = (event) => {
    setValue(event.target.value);
    setFieldValue("paymentMethod", event.target.value);
    if (event.target.value === "others" || event.target.value === "credits") {
      setPayByPackageCode(false);
      setFieldValue("packageCode", "");
    } else {
      setPayByPackageCode(true);
      setFieldValue("packageCode", event.target.value);
    }
  };
  return (
    <FormControl sx={{ width: "100%" }}>
      <RadioGroup name="controlled-radio-buttons-group" value={value} onChange={handleChange}>
        {!isPackageRedeemableOnly && (
          <>
            <FormControlLabel
              value="others"
              control={<StyledRadio />}
              label={
                <LabelContainer>
                  <WifiRoundedIcon />
                  <LabelText>Online Banking / E-Wallet</LabelText>
                </LabelContainer>
              }
            />
            <SeperateLine />
          </>
        )}

        {!isPackageRedeemableOnly && (
          <>
            <FormControlLabel
              value="credits"
              control={<StyledRadio />}
              label={
                <LabelContainer>
                  <AccountBalanceWalletOutlinedIcon />
                  {getProfileDetailObj.status === "succeeded" && (
                    <LabelText>{`Account Credits (Balance RM ${getProfileDetailObj.data.creditBalance})`}</LabelText>
                  )}
                </LabelContainer>
              }
            />
            <SeperateLine />
          </>
        )}
        {getFitnessClassBookingPaymentOptionObj.status === "succeeded" &&
          getFitnessClassBookingPaymentOptionObj.data.options.package.availableCodes.length > 0 &&
          getFitnessClassBookingPaymentOptionObj.data.options.package.availableCodes.map(
            (item, index) => (
              <div key={item.code}>
                {index !== 0 && <SeperateLine />}
                <FormControlLabel
                  value={item.code}
                  control={<StyledRadio />}
                  label={
                    <LabelContainer>
                      <LabelText>{`Redeem with ${item.code} - ${item.remaining} sessions remaining`}</LabelText>
                    </LabelContainer>
                  }
                />
              </div>
            ),
          )}
      </RadioGroup>
    </FormControl>
  );
}

PaymentMethodCheckbox.propTypes = {
  setPayByPackageCode: PropTypes.func.isRequired,
  classDetails: PropTypes.shape({
    booking: PropTypes.shape({
      status: PropTypes.string,
      session: PropTypes.shape({
        requestedPackage: PropTypes.shape({
          id: PropTypes.number,
        }),
      }),
    }),
  }).isRequired,
};

export default PaymentMethodCheckbox;
