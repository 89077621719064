import { Box, Modal, styled, useMediaQuery, useTheme } from "@mui/material";
import { Parser } from "html-to-react";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import TopTitleBox from "../../../components/utils/top-title-box.component";
import { readNotification } from "../../../services/notification/notification-slice.service";

const ModalBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  position: "absolute",
  top: !isMobile && "50%",
  left: !isMobile && "50%",
  transform: !isMobile && "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.white,
  borderRadius: !isMobile && theme.shape.borderRadius[2],
  boxShadow: 24,
  maxWidth: !isMobile && "550px",
  width: isMobile ? "100%" : "90%",
  height: isMobile && "100%",
  outline: "none",
  overflowY: "auto",
}));

function NotificationDetailsModal({ showModal, setShowModal, notificationDetails }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();

  useEffect(() => {
    if (notificationDetails) {
      dispatch(readNotification(notificationDetails.id));
    }
  }, [notificationDetails]);

  return (
    <Modal open={showModal} onClose={() => setShowModal(false)}>
      <ModalBox isMobile={isMobile}>
        {notificationDetails && (
          <Box sx={{ maxHeight: isMobile ? "100%" : "600px", overflowY: "auto", padding: "20px" }}>
            <TopTitleBox
              title="Announcement"
              requireCloseButton={true}
              setShowModal={setShowModal}
            />
            <div id="notificationContent" style={{ textAlign: "justify" }}>
              {Parser().parse(notificationDetails.content)}
            </div>
          </Box>
        )}
      </ModalBox>
    </Modal>
  );
}

NotificationDetailsModal.defaultProps = {
  notificationDetails: PropTypes.shape({
    id: null,
    header: "",
    subheader: "",
    content: "",
    type: "",
    read: false,
    dateNotified: "",
  }),
};

NotificationDetailsModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  notificationDetails: PropTypes.shape({
    id: PropTypes.number,
    header: PropTypes.string,
    subheader: PropTypes.string,
    content: PropTypes.string,
    type: PropTypes.string,
    read: PropTypes.bool,
    dateNotified: PropTypes.string,
  }),
};

export default NotificationDetailsModal;
