import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import {
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import KatchLogo from "../../../assets/images/katch_logo";
import playstore from "../../../assets/images/playstore.png";
import appstore from "../../../assets/images/appstore.png";
import huaweiAppGallery from "../../../assets/images/huawei-app-gallery.png";
import LandingBackground from "../../../assets/images/landing.png";
import LandingUserBackground from "../../../assets/images/landing-user-image.png";
import LandingMerchantBackground from "../../../assets/images/landing-merchant-image.png";
import CustomButton from "../../../components/button/custom-button.component";
import routes from "../../../components/navigation/routes";
import Spacer from "../../../components/utils/spacer.component";

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.brand.white,
}));

const StyledMenu = styled((props) => <Menu elevation={0} {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 18,
    minWidth: 160,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "5px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
    },
  },
}));

function LandingScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [downloadAppMenu, setDownloadAppMenu] = useState(null);
  const navigate = useNavigate();

  const handleOpenAppMenu = (event) => {
    setDownloadAppMenu(event.currentTarget);
  };

  const handleCloseAppMenu = () => {
    setDownloadAppMenu(null);
  };

  const renderTopLogoAndGetTheApp = () => (
    <Grid item container justifyContent="space-between" xs={12} justifySelf="center">
      <KatchLogo color="white" />
      <Grid item display="flex" alignItems="center">
        <CustomButton
          fontWeight="bold"
          variant="text"
          textColor={theme.palette.colors.brand.white}
          fontSize={theme.fonts.fontSizes.size16}
          onClick={handleOpenAppMenu}
        >
          GET THE APP
        </CustomButton>
        <StyledMenu
          sx={{ mt: "35px" }}
          id="menu-appbar"
          anchorEl={downloadAppMenu}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={Boolean(downloadAppMenu)}
          onClose={handleCloseAppMenu}
        >
          <MenuItem
            key={1}
            onClick={() => {
              handleCloseAppMenu();
              window.open(
                `https://play.google.com/store/apps/details?id=com.engageapp.mobile&hl=en`,
                "_blank",
              );
            }}
          >
            <img src={playstore} alt="playstore_logo" width="150px" />
          </MenuItem>
          <MenuItem
            key={2}
            onClick={() => {
              handleCloseAppMenu();
              window.open(`https://apps.apple.com/my/app/katch/id1606341748`, "_blank");
            }}
          >
            <img src={appstore} alt="appstore_logo" width="150px" />
          </MenuItem>
          <MenuItem
            key={2}
            onClick={() => {
              handleCloseAppMenu();
              window.open(`https://appgallery.huawei.com/app/C106856797`, "_blank");
            }}
          >
            <img src={huaweiAppGallery} alt="huawei_logo" width="150px" />
          </MenuItem>
        </StyledMenu>
      </Grid>
    </Grid>
  );

  const renderUserExplore = () => (
    <Grid item sx={{ textAlign: isMobile ? "center" : "left" }}>
      <StyledTypography>USER</StyledTypography>
      <StyledTypography
        fontWeight="bold"
        fontSize={isMobile ? theme.fonts.fontSizes.size24 : theme.fonts.fontSizes.size32}
      >
        One app for
      </StyledTypography>
      <StyledTypography
        fontWeight="bold"
        fontSize={isMobile ? theme.fonts.fontSizes.size24 : theme.fonts.fontSizes.size32}
      >
        Health, Fitness and
      </StyledTypography>
      <StyledTypography
        fontWeight="bold"
        fontSize={isMobile ? theme.fonts.fontSizes.size24 : theme.fonts.fontSizes.size32}
      >
        Wellness.
      </StyledTypography>
      <Spacer size="m" />
      <Grid item xs={11}>
        <CustomButton
          width="200px"
          fontSize={theme.fonts.fontSizes.size18}
          onClick={() => navigate(routes.HOME)}
        >
          EXPLORE
        </CustomButton>
      </Grid>
    </Grid>
  );

  const renderMerchantExplore = () => (
    <Grid item sx={{ textAlign: isMobile ? "center" : "right" }}>
      {isMobile && <Spacer size="l" />}
      <StyledTypography>MERCHANT</StyledTypography>
      <StyledTypography
        fontWeight="bold"
        fontSize={isMobile ? theme.fonts.fontSizes.size24 : theme.fonts.fontSizes.size32}
      >
        One app for
      </StyledTypography>
      <StyledTypography
        fontWeight="bold"
        fontSize={isMobile ? theme.fonts.fontSizes.size24 : theme.fonts.fontSizes.size32}
      >
        Health, Fitness and
      </StyledTypography>
      <StyledTypography
        fontWeight="bold"
        fontSize={isMobile ? theme.fonts.fontSizes.size24 : theme.fonts.fontSizes.size32}
      >
        Wellness.
      </StyledTypography>
      <Spacer size="m" />
      <Grid container justifyContent={isMobile ? "center" : "flex-end"}>
        <CustomButton
          width="200px"
          fontSize={theme.fonts.fontSizes.size18}
          onClick={() => window.open(`https://merchant.katch.asia/`, "_self")}
        >
          EXPLORE
        </CustomButton>
      </Grid>
    </Grid>
  );

  const renderSocialButton = () => (
    <Grid item>
      <IconButton onClick={() => window.open(`https://www.facebook.com/katchmalaysia`, "_blank")}>
        <FacebookOutlinedIcon color="white" sx={{ fontSize: 35 }} />
      </IconButton>
      <IconButton onClick={() => window.open(`https://www.instagram.com/katch_my/`, "_blank")}>
        <InstagramIcon color="white" sx={{ fontSize: 35 }} />
      </IconButton>
    </Grid>
  );

  const renderPCViewLanding = () => (
    <Box
      sx={{
        position: "absolute",
        height: "100%",
        width: "100%",
        backgroundImage: `url(${LandingBackground})`,
        backgroundPosition: "top",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Grid
        item
        display="grid"
        flexDirection="column"
        alignContent="space-between"
        height="90%"
        padding="30px"
        paddingX="5%"
        xs={12}
      >
        {renderTopLogoAndGetTheApp()}
        <Grid item container justifyContent="space-between" xs={12} justifySelf="center">
          {renderUserExplore()}

          {renderMerchantExplore()}
        </Grid>
      </Grid>
      <Grid paddingRight="5%">
        <Grid container justifyContent="flex-end">
          {renderSocialButton()}
        </Grid>
      </Grid>
    </Box>
  );

  const renderMobileViewLanding = () => (
    <Box
      sx={{
        position: "absolute",
        height: "100%",
        width: "100%",
      }}
    >
      <Box
        sx={{
          height: "50%",
          width: "100%",
          backgroundImage: `url(${LandingUserBackground})`,
          backgroundPosition: "top",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          padding: "20px",
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            zIndex: 99,
            width: "90%",
            left: 0,
            right: 0,
            margin: "auto",
          }}
        >
          {renderTopLogoAndGetTheApp()}
        </Box>
        <Box
          sx={{
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
          }}
        >
          {renderUserExplore()}
        </Box>
      </Box>
      <Box
        sx={{
          height: "50%",
          width: "100%",
          backgroundImage: `url(${LandingMerchantBackground})`,
          backgroundPosition: "top",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          padding: "20px",
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
          }}
        >
          {renderMerchantExplore()}
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: "2%",
            left: 0,
            right: 0,
            margin: "auto",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {renderSocialButton()}
        </Box>
      </Box>
    </Box>
  );

  return isMobile ? renderMobileViewLanding() : renderPCViewLanding();
}

export default LandingScreen;
