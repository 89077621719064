import { Box, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import CustomButton from "../../../../../components/button/custom-button.component";
import FormFieldTextWithIcon from "../../../../../components/forms/form-field-text-with-icon.component";
import FormSubmitButton from "../../../../../components/forms/form-submit-button.component";
import Form from "../../../../../components/forms/form.component";
import routes from "../../../../../components/navigation/routes";
import Loading from "../../../../../components/notification/backdrop-loading.component";
import DialogBox from "../../../../../components/notification/dialog-box.component";
import InsufficientCreditModal from "../../../../../components/notification/insufficient-credit-modal.component";
import { SnackbarContext } from "../../../../../components/notification/snackbar.context";
import HorizontalDivider from "../../../../../components/utils/horizontal-divider.component";
import PaddedView from "../../../../../components/utils/padded-view.component";
import CustomSkeleton from "../../../../../components/utils/skeleton.component";
import Spacer from "../../../../../components/utils/spacer.component";
import {
  fitnessClassSessionBookingSelector,
  getFitnessClassBookingPaymentOption,
  payFitnessClassBooking,
} from "../../../../../services/fitness_class/session/booking/fitness-class-session-booking-slice.service";
import { getProfileDetail } from "../../../../../services/profile/profile-slice.service";
import PaymentMethodCheckbox from "../components/payment-method-checkbox.component";
import PreviewSummaryLoader from "../loader/preview-summary-loader.component";

const TitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size22,
  fontWeight: "bold",
}));

const SectionTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size18,
  fontWeight: "bold",
}));

const BodyTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size16,
}));

const SectionContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.colors.bg.primary,
  borderRadius: `${theme.shape.borderRadius[2]}px`,
}));

function FitnessClassBookingRequestPreviewSummaryScreen() {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getFitnessClassBookingPaymentOptionObj } = useSelector(
    fitnessClassSessionBookingSelector,
  );
  const createSnackBar = useContext(SnackbarContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const formRef = useRef();
  const promoCodeFormRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showInsufficientCreditModal, setShowInsufficientCreditModal] = useState(false);
  const [redeemSuccess, setRedeemSuccess] = useState(false);
  const [isRedeemingPromo, setIsRedeemingPromo] = useState(false);
  const classDetails = location.state.item;
  const isPackageRedeemableOnly =
    classDetails.booking.status === "merchant_request" &&
    classDetails.booking.session.requestedPackage.id !== null;
  const [payByPackageCode, setPayByPackageCode] = useState(isPackageRedeemableOnly);
  const validationSchema = Yup.object().shape({
    slotId: Yup.number().required().label("Slot Id"),
    paymentMethod: Yup.string().required().label("Payment Method"),
    packageCode: Yup.string().nullable().label("Package Code"),
    promoCode: Yup.string().nullable().label("Promo Code"),
  });

  const previewFitness = (values, promoRedeem) => {
    if (promoRedeem) {
      setIsRedeemingPromo(true);
      setIsLoading(true);
    }

    dispatch(getFitnessClassBookingPaymentOption(values)).then(({ meta, error }) => {
      setIsLoading(false);

      if (meta.requestStatus === "fulfilled") {
        formRef.current.setFieldValue("promoCode", values.promoCode);
        setRedeemSuccess(true);
      }

      if (meta.requestStatus === "rejected") {
        formRef.current.setFieldValue("promoCode", "");
        promoCodeFormRef.current.setFieldValue("promoCode", "");
        previewFitness({ slotId: classDetails.id, promoCode: "" }, true);
        setRedeemSuccess(false);
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const clearPromoForm = () => {
    formRef.current.setFieldValue("promoCode", "");
    promoCodeFormRef.current.setFieldValue("promoCode", "");
    previewFitness({ slotId: classDetails.id, promoCode: "" }, true);
  };

  const handleFormSubmission = (values) => {
    setIsLoading(true);
    const payloadValues = { ...values };
    if (payByPackageCode) {
      payloadValues.paymentMethod = "others";
    }
    dispatch(payFitnessClassBooking(payloadValues)).then(({ meta, error, payload }) => {
      setIsLoading(false);

      if (meta.requestStatus === "fulfilled") {
        if (
          payByPackageCode ||
          values.paymentMethod === "credits" ||
          payload.data.paymentTransaction.status === "paid"
        ) {
          dispatch(getProfileDetail());
          navigate(routes.FITNESSCLASSSESSIONBOOKINGPAYMENTSUCCESS, {
            state: { ...payload.data },
          });
          createSnackBar({
            message: payload.message,
            type: "success",
            open: true,
          });
        } else {
          window.open(payload.data.bill.remoteUrl, "_self");
        }
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleSubmit = (values) => {
    if (
      values.paymentMethod === "credits" &&
      !payByPackageCode &&
      !getFitnessClassBookingPaymentOptionObj.data.options.credit.creditPurchasable
    ) {
      setShowInsufficientCreditModal(true);
      return;
    }

    handleFormSubmission(values);
  };

  useEffect(() => {
    dispatch(getFitnessClassBookingPaymentOption({ slotId: classDetails.id, promoCode: "" }));
  }, []);

  return (
    <Box>
      {getFitnessClassBookingPaymentOptionObj.status === "succeeded" && (
        <InsufficientCreditModal
          isShow={showInsufficientCreditModal}
          setIsShowAlert={setShowInsufficientCreditModal}
        />
      )}
      <DialogBox
        title="Booking is non-refundable."
        isShow={showConfirmModal}
        setIsShowAlert={setShowConfirmModal}
        message={
          <Grid item>
            <Typography sx={{ textAlign: "center" }}>
              By continuing, you agree that this booking cannot be cancelled or rescheduled.
            </Typography>
          </Grid>
        }
        isConfirmHandle={() => {
          setShowConfirmModal(false);
          formRef.current.handleSubmit();
        }}
        buttonText="Ok"
      />

      <Loading isLoading={isLoading} />
      <Spacer size="l" />
      <PaddedView multiples={2}>
        <TitleTypography>Booking Details</TitleTypography>
        <Spacer size="l" />
        {classDetails || isRedeemingPromo ? (
          <Form
            innerRef={formRef}
            initialValues={{
              slotId: classDetails.id,
              paymentMethod: isPackageRedeemableOnly
                ? classDetails.booking.session.requestedPackage.code
                : "credits",
              packageCode: isPackageRedeemableOnly
                ? classDetails.booking.session.requestedPackage.code
                : "",
              promoCode: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Grid container columnSpacing={2} rowSpacing={2}>
              {classDetails && (
                <>
                  <Grid container item xs={isMobile ? 12 : 7} flexDirection="column" rowSpacing={2}>
                    <Grid item>
                      <SectionContainer item>
                        <PaddedView multiples={isMobile ? 3 : 4}>
                          {payByPackageCode && (
                            <>
                              <Grid item display="flex" alignItems="center">
                                <Typography
                                  fontSize={theme.fonts.fontSizes.size14}
                                  sx={{
                                    border: "1px solid",
                                    borderColor: theme.palette.colors.ui.error,
                                    color: theme.palette.colors.ui.error,
                                    paddingX: "10px",
                                  }}
                                >
                                  NON-REFUNDABLE
                                </Typography>
                              </Grid>
                              <Spacer size="m" />
                            </>
                          )}
                          <Typography fontSize={theme.fonts.fontSizes.size14}>
                            {classDetails.booking.session.class.category.label}
                          </Typography>
                          <Spacer />
                          <Grid item key={uuidv4()}>
                            <Grid container justifyContent="space-between" alignItems="center">
                              <TitleTypography>
                                {classDetails.booking.session.class.name}
                              </TitleTypography>
                              <BodyTypography color="primary" fontWeight="bold">
                                RM {classDetails.booking.price}
                              </BodyTypography>
                            </Grid>
                            <Spacer size="m" />
                            <Grid item>
                              <BodyTypography>
                                {classDetails.booking.session.startTime} (
                                {classDetails.booking.session.durationMins}
                                mins) | {classDetails.booking.date}
                              </BodyTypography>
                              <BodyTypography>{classDetails.booking.location.name}</BodyTypography>
                            </Grid>
                            <Spacer size="m" />
                            <Grid item container rowSpacing={1}>
                              <Grid item xs={12}>
                                <Typography fontWeight="bold">Staffs</Typography>
                              </Grid>
                              <Grid container item columnSpacing={2}>
                                {classDetails.booking.session.staffs.map((item) => (
                                  <Grid item key={item.id}>
                                    <Typography>
                                      w/ {item.firstName} {item.lastName}
                                    </Typography>
                                  </Grid>
                                ))}
                              </Grid>
                            </Grid>
                          </Grid>
                        </PaddedView>
                      </SectionContainer>
                    </Grid>

                    {!payByPackageCode && (
                      <Grid item>
                        <SectionContainer item>
                          <PaddedView multiples={isMobile ? 3 : 4}>
                            <SectionTypography>Promos & Vouchers</SectionTypography>
                            <Spacer size="m" />

                            <Form
                              innerRef={promoCodeFormRef}
                              initialValues={{
                                slotId: classDetails.id,
                                promoCode: "",
                              }}
                              validationSchema={Yup.object().shape({
                                promoCode: Yup.string().required().label("Promo Code"),
                              })}
                              onSubmit={(values) => {
                                previewFitness(values, true);
                              }}
                            >
                              <Grid container columnSpacing={2}>
                                <Grid item xs={8}>
                                  <FormFieldTextWithIcon
                                    name="promoCode"
                                    placeholder="Promo Code"
                                    toUpper={true}
                                    clearPromoForm={clearPromoForm}
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <FormSubmitButton width="100%" borderRadius={3}>
                                    <Typography>Redeem</Typography>
                                  </FormSubmitButton>
                                </Grid>
                              </Grid>
                            </Form>
                          </PaddedView>
                        </SectionContainer>
                      </Grid>
                    )}

                    <Grid item>
                      <SectionContainer item>
                        <PaddedView multiples={isMobile ? 3 : 4}>
                          <SectionTypography>Payment Method</SectionTypography>
                          <Spacer size="m" />
                          <PaymentMethodCheckbox
                            setPayByPackageCode={setPayByPackageCode}
                            classDetails={classDetails}
                          />
                        </PaddedView>
                      </SectionContainer>
                    </Grid>
                  </Grid>
                  {getFitnessClassBookingPaymentOptionObj.status === "succeeded" ? (
                    <Grid item xs={isMobile ? 12 : 5}>
                      <SectionContainer item>
                        <PaddedView multiples={isMobile ? 3 : 4}>
                          <TitleTypography>Payment Summary</TitleTypography>
                          <Spacer size="m" />
                          <Grid
                            item
                            display="grid"
                            flexDirection="column"
                            alignContent="space-between"
                            minHeight="220px"
                          >
                            <Grid
                              container
                              item
                              dispaly="flex"
                              justifyContent="space-between"
                              rowSpacing={1}
                            >
                              <Grid item xs={6}>
                                <BodyTypography>Subtotal</BodyTypography>
                              </Grid>
                              <Grid item xs={6} textAlign="right">
                                <BodyTypography>
                                  RM{" "}
                                  {
                                    getFitnessClassBookingPaymentOptionObj.data.paymentSummary
                                      .subtotal
                                  }
                                </BodyTypography>
                              </Grid>
                              {payByPackageCode && (
                                <>
                                  <Grid item xs={6}>
                                    <BodyTypography>Discount</BodyTypography>
                                  </Grid>
                                  <Grid item xs={6} textAlign="right">
                                    <BodyTypography>
                                      -RM {classDetails.booking.price}
                                    </BodyTypography>
                                  </Grid>
                                </>
                              )}
                              {getFitnessClassBookingPaymentOptionObj.data?.paymentSummary
                                .voucherDiscount !== "0.00" &&
                                redeemSuccess && (
                                  <>
                                    <Grid item xs={6}>
                                      <BodyTypography>Voucher Discount</BodyTypography>
                                    </Grid>
                                    <Grid item xs={6} textAlign="right">
                                      <BodyTypography>
                                        -RM{" "}
                                        {
                                          getFitnessClassBookingPaymentOptionObj.data
                                            ?.paymentSummary.voucherDiscount
                                        }
                                      </BodyTypography>
                                    </Grid>
                                  </>
                                )}
                            </Grid>
                            <Grid item>
                              <HorizontalDivider spacerSize="l" />
                              <Grid
                                container
                                item
                                dispaly="flex"
                                justifyContent="space-between"
                                rowSpacing={1}
                              >
                                <SectionTypography>Total Price</SectionTypography>
                                <SectionTypography>
                                  RM{" "}
                                  {payByPackageCode
                                    ? "0.00"
                                    : getFitnessClassBookingPaymentOptionObj.data.paymentSummary
                                        .totalPrice}
                                </SectionTypography>
                              </Grid>
                              <Spacer size="xl" />
                              <Grid item>
                                <CustomButton
                                  fontSize={theme.fonts.fontSizes.size16}
                                  onClick={() => {
                                    if (payByPackageCode) {
                                      setShowConfirmModal(true);
                                    } else {
                                      formRef.current.handleSubmit();
                                    }
                                  }}
                                >
                                  Confirm
                                </CustomButton>
                              </Grid>
                            </Grid>
                          </Grid>
                        </PaddedView>
                      </SectionContainer>
                    </Grid>
                  ) : (
                    <Grid item xs={isMobile ? 12 : 5}>
                      <SectionContainer item>
                        <PaddedView multiples={isMobile ? 3 : 4}>
                          <CustomSkeleton height="33px" width="250px" />
                          <Spacer size="m" />
                          <Grid
                            item
                            display="grid"
                            flexDirection="column"
                            alignContent="space-between"
                            minHeight="220px"
                          >
                            <Grid
                              container
                              item
                              dispaly="flex"
                              justifyContent="space-between"
                              rowSpacing={1}
                            >
                              <CustomSkeleton />
                              <CustomSkeleton />
                            </Grid>
                            <Grid item>
                              <HorizontalDivider spacerSize="l" />
                              <Grid
                                container
                                item
                                dispaly="flex"
                                justifyContent="space-between"
                                rowSpacing={1}
                              >
                                <CustomSkeleton
                                  height="27px"
                                  width={isMobile ? "100px" : "200px"}
                                />
                                <CustomSkeleton
                                  height="27px"
                                  width={isMobile ? "100px" : "150px"}
                                />
                              </Grid>
                              <Spacer size="xl" />
                              <Grid item>
                                <CustomSkeleton height="40px" width="100%" />
                              </Grid>
                            </Grid>
                          </Grid>
                        </PaddedView>
                      </SectionContainer>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </Form>
        ) : (
          <PreviewSummaryLoader />
        )}
      </PaddedView>
    </Box>
  );
}

export default FitnessClassBookingRequestPreviewSummaryScreen;
