import { Box, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import FormFieldTextWithIcon from "../../../../components/forms/form-field-text-with-icon.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import Form from "../../../../components/forms/form.component";
import PaymentMethodCheckbox from "../../../../components/forms/payment-method-checkbox.component";
import routes from "../../../../components/navigation/routes";
import Loading from "../../../../components/notification/backdrop-loading.component";
import InsufficientCreditModal from "../../../../components/notification/insufficient-credit-modal.component";
import { SnackbarContext } from "../../../../components/notification/snackbar.context";
import UnverifiedUserModal from "../../../../components/notification/unverified-user-modal.component";
import HorizontalDivider from "../../../../components/utils/horizontal-divider.component";
import PaddedView from "../../../../components/utils/padded-view.component";
import Spacer from "../../../../components/utils/spacer.component";
import { isLogin } from "../../../../infrastructure/utils";
import {
  createEventBooking,
  eventBookingSelector,
  previewEventBooking,
} from "../../../../services/event/booking/event-booking-slice.service";
import {
  eventSessionSelector,
  getEventSessionDetails,
} from "../../../../services/event/session/event-session-slice.service";
import { getProfileDetail } from "../../../../services/profile/profile-slice.service";
import EventCard from "../components/event-card.component";
import GuestDetail from "../components/guest-detail.component";
import QuestionResponse from "../components/question-response.component";
import PreviewLoader from "../loaders/preview-loader.component";

const TitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size22,
  fontWeight: "bold",
}));

const SectionTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size18,
  fontWeight: "bold",
}));

const BodyTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size16,
}));

const SectionContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.colors.bg.primary,
  borderRadius: `${theme.shape.borderRadius[2]}px`,
}));

function EventPreviewSummaryScreen() {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies(["guest-payment-token"]); // eslint-disable-line no-unused-vars
  const { previewEventBookingObj, createEventBookingObj } = useSelector(eventBookingSelector);
  const { getEventSessionDetailsObj } = useSelector(eventSessionSelector);
  const createSnackBar = useContext(SnackbarContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const formRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const promoCodeFormRef = useRef();
  const [redeemPromo, setRedeemPromo] = useState(false);
  const [showInsufficientCreditModal, setShowInsufficientCreditModal] = useState(false);
  const [allQuestionsAnswered, setAllQuestionsAnswered] = useState(false);

  const validationSchema = Yup.object().shape({
    eventSessionId: Yup.number().required().label("Event Id"),
    paymentMethod: Yup.string().required().label("Payment Method"),
    promoCode: Yup.string().nullable().label("Promo Code"),
    email: Yup.string()
      .when({
        is: () => !isLogin(),
        then: (rule) => rule.required().email(),
      })
      .label("Email"),
    phone: Yup.string()
      .when({
        is: () => !isLogin(),
        then: (rule) => rule.min(5).required(),
      })
      .label("Phone Number"),
    firstName: Yup.string()
      .when({
        is: () => !isLogin(),
        then: (rule) => rule.required().min(2),
      })
      .label("First Name"),
    lastName: Yup.string()
      .when({
        is: () => !isLogin(),
        then: (rule) => rule.required().min(2),
      })
      .label("Last Name"),
  });

  const previewEvent = (values, promoRedeem) => {
    if (promoRedeem) {
      setRedeemPromo(true);
      setIsLoading(true);
    }
    dispatch(getEventSessionDetails(values.eventSessionId));

    dispatch(previewEventBooking(values)).then(({ meta, error }) => {
      setIsLoading(false);

      if (meta.requestStatus === "fulfilled") {
        formRef.current.setFieldValue("promoCode", values.promoCode);
      }
      if (meta.requestStatus === "rejected") {
        if (promoRedeem) {
          formRef.current.setFieldValue("promoCode", "");
          promoCodeFormRef.current.setFieldValue("promoCode", "");
          previewEvent({ ...formRef.current.values, promoCode: "" }, true);
        }
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const clearPromoForm = () => {
    formRef.current.setFieldValue("promoCode", "");
    promoCodeFormRef.current.setFieldValue("promoCode", "");
    previewEvent({ ...formRef.current.values, promoCode: "" }, true);
  };

  const handleSubmit = (values) => {
    if (values.paymentMethod === "credits" && !previewEventBookingObj.data.creditPurchasable) {
      setShowInsufficientCreditModal(true);
      return;
    }
    setIsLoading(true);
    dispatch(createEventBooking(values)).then(({ meta, error, payload }) => {
      setIsLoading(false);

      if (meta.requestStatus === "fulfilled") {
        if (
          values.paymentMethod === "credits" ||
          payload.data.paymentTransaction.status === "paid"
        ) {
          dispatch(getProfileDetail());
          navigate(routes.EVENTBOOKINGPAYMENTSUCCESS, {
            state: { ...payload.data },
          });
          createSnackBar({
            message: payload.message,
            type: "success",
            open: true,
          });
        } else {
          if (payload.data.token) {
            const date = new Date();
            date.setMonth(date.getMonth() + 1);

            setCookie("guest-payment-token", payload.data.token, { path: "/", expires: date });
          }

          window.open(payload.data.bill.remoteUrl, "_self");
        }
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  useEffect(() => {
    previewEvent(location.state, false);
  }, []);

  return (
    <Box>
      {getEventSessionDetailsObj.status === "succeeded" &&
        getEventSessionDetailsObj.data.requireVerification && (
          <UnverifiedUserModal cancelAction={() => navigate(-1)} />
        )}

      {previewEventBookingObj.status === "succeeded" && (
        <InsufficientCreditModal
          isShow={showInsufficientCreditModal}
          setIsShowAlert={setShowInsufficientCreditModal}
        />
      )}
      <Loading isLoading={isLoading} />
      <Spacer size="l" />
      <PaddedView multiples={2}>
        <TitleTypography>Booking Details</TitleTypography>
        <Spacer size="l" />
        {redeemPromo ||
        (previewEventBookingObj.status === "succeeded" &&
          getEventSessionDetailsObj.status === "succeeded") ? (
          <Form
            innerRef={formRef}
            initialValues={{
              eventSessionId: location.state.eventSessionId,
              eventSessionCategoryIds: location.state.eventSessionCategoryIds,
              paymentMethod: "others",
              promoCode: "",
              questions: [],
              email: "",
              phone: "",
              firstName: "",
              lastName: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Grid container columnSpacing={2} rowSpacing={2}>
              <Grid container item xs={isMobile ? 12 : 7} flexDirection="column" rowSpacing={2}>
                <Grid item>
                  <EventCard itemDetails={previewEventBookingObj.data} />
                </Grid>
                <Grid item>
                  <SectionContainer item>
                    <PaddedView multiples={isMobile ? 2 : 4}>
                      <SectionTypography>Promos & Vouchers</SectionTypography>
                      <Spacer size="m" />

                      <Form
                        innerRef={promoCodeFormRef}
                        initialValues={{
                          promoCode: "",
                        }}
                        validationSchema={Yup.object().shape({
                          promoCode: Yup.string().required().label("Promo Code"),
                        })}
                        onSubmit={(values) => {
                          previewEvent(
                            {
                              ...formRef.current.values,
                              ...values,
                            },
                            true,
                          );
                        }}
                      >
                        <Grid container columnSpacing={2}>
                          <Grid item xs={8}>
                            <FormFieldTextWithIcon
                              name="promoCode"
                              placeholder="Promo Code"
                              toUpper={true}
                              clearPromoForm={clearPromoForm}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <FormSubmitButton width="100%" borderRadius={3}>
                              <Typography>Redeem</Typography>
                            </FormSubmitButton>
                          </Grid>
                        </Grid>
                      </Form>
                    </PaddedView>
                  </SectionContainer>
                </Grid>
                <Grid item>
                  <SectionContainer item>
                    <PaddedView multiples={isMobile ? 2 : 4}>
                      <SectionTypography>Payment Method</SectionTypography>
                      <Spacer size="m" />
                      <PaymentMethodCheckbox />
                    </PaddedView>
                  </SectionContainer>
                </Grid>
              </Grid>

              <Grid item xs={isMobile ? 12 : 5}>
                {!isLogin() && (
                  <Grid item>
                    <SectionContainer item>
                      <PaddedView multiples={isMobile ? 2 : 4}>
                        <SectionTypography>Your Information</SectionTypography>
                        <Spacer size="m" />
                        <GuestDetail />
                      </PaddedView>
                    </SectionContainer>
                    <Spacer size="m" />
                  </Grid>
                )}
                {getEventSessionDetailsObj.data.requireQuestionResponse && (
                  <Grid item>
                    <SectionContainer item>
                      <PaddedView multiples={isMobile ? 2 : 4}>
                        <SectionTypography>Questions & Responses</SectionTypography>
                        <Spacer size="m" />
                        <QuestionResponse
                          items={getEventSessionDetailsObj?.data?.questions}
                          setAllQuestionsAnswered={setAllQuestionsAnswered}
                        />
                      </PaddedView>
                    </SectionContainer>
                    <Spacer size="m" />
                  </Grid>
                )}
                <SectionContainer item>
                  <PaddedView multiples={isMobile ? 2 : 4}>
                    <TitleTypography>Payment Summary</TitleTypography>
                    <Spacer size="m" />
                    <Grid
                      item
                      display="grid"
                      flexDirection="column"
                      alignContent="space-between"
                      minHeight="220px"
                    >
                      <Grid
                        container
                        item
                        dispaly="flex"
                        justifyContent="space-between"
                        rowSpacing={1}
                      >
                        <Grid item xs={6}>
                          <BodyTypography>Subtotal</BodyTypography>
                        </Grid>
                        <Grid item xs={6} textAlign="right">
                          <BodyTypography>
                            RM {previewEventBookingObj.data?.paymentSummary.subtotal}
                          </BodyTypography>
                        </Grid>

                        {previewEventBookingObj.data?.paymentSummary.voucherDiscount !== "0.00" && (
                          <>
                            <Grid item xs={6}>
                              <BodyTypography>Voucher Discount</BodyTypography>
                            </Grid>
                            <Grid item xs={6} textAlign="right">
                              <BodyTypography>
                                -RM {previewEventBookingObj.data?.paymentSummary.voucherDiscount}
                              </BodyTypography>
                            </Grid>
                          </>
                        )}
                      </Grid>
                      <Grid item>
                        <HorizontalDivider spacerSize="l" />
                        <Grid
                          container
                          item
                          dispaly="flex"
                          justifyContent="space-between"
                          rowSpacing={1}
                        >
                          <SectionTypography>Total Price</SectionTypography>
                          <SectionTypography>
                            RM {previewEventBookingObj.data?.paymentSummary.totalPrice}
                          </SectionTypography>
                        </Grid>
                        <Spacer size="xl" />
                        <Grid item>
                          <FormSubmitButton
                            width="100%"
                            borderRadius={3}
                            disabled={
                              createEventBookingObj.status === "pending" ||
                              (!allQuestionsAnswered &&
                                getEventSessionDetailsObj.data.requireQuestionResponse)
                            }
                          >
                            Confirm
                          </FormSubmitButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </PaddedView>
                </SectionContainer>
              </Grid>
            </Grid>
          </Form>
        ) : (
          <PreviewLoader />
        )}
      </PaddedView>
    </Box>
  );
}

export default EventPreviewSummaryScreen;
