import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import Tick from "../../../../assets/images/circle_tick";
import routes from "../../../../components/navigation/routes";
import PaddedView from "../../../../components/utils/padded-view.component";
import Spacer from "../../../../components/utils/spacer.component";
import TopTitleBox from "../../../../components/utils/top-title-box.component";

function ResetSuccessful() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: theme.palette.colors.bg.white,
          borderRadius: `${theme.shape.borderRadius[2]}px`,
          flex: 1,
          maxWidth: 500,
          minWidth: isMobile ? null : 450,
          alignItems: "center",
          justifyContent: "center",
          boxShadow: 2,
        }}
      >
        <Spacer size="m" />
        <TopTitleBox title="Reset Password Successfully" requireCloseButton={false} />
        <Spacer size="m" />
        <PaddedView multiples={isMobile ? 3 : 4}>
          <Grid container justifyContent="center">
            <Tick />
          </Grid>
          <Spacer size="m" />
          <Typography varian="h2" textAlign="center">
            You can sign in with your new password now.
          </Typography>
          <Box sx={{ width: 50, height: 50 }} />
          <Box
            sx={{
              "& .MuiButton-root": {
                borderTopLeftRadius: theme.shape.borderRadius[2],
                borderTopRightRadius: theme.shape.borderRadius[2],
                borderBottomLeftRadius: theme.shape.borderRadius[2],
                borderBottomRightRadius: theme.shape.borderRadius[2],
                textTransform: "none",
                padding: 1,
                "&:hover": {
                  backgroundColor: theme.palette.colors.brand.primary,
                },
              },
            }}
          >
            <Button
              variant="contained"
              onClick={() => {
                navigate(routes.LOGIN);
              }}
              sx={{ backgroundColor: theme.palette.colors.brand.primary, width: "100%" }}
            >
              Sign In
            </Button>
          </Box>
        </PaddedView>
      </Box>
    </Box>
  );
}

export default ResetSuccessful;
