import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq, putReq } from "../api.services";

export const getProfileDetail = createAsyncThunk("profile/detail", async (payload) => {
  const response = await getReq(`${process.env.REACT_APP_API_PRIVATE_V1}/users`, payload);
  return response;
});

export const updatePassword = createAsyncThunk("profile/update/password", async (payload) => {
  const response = await putReq(`${process.env.REACT_APP_API_PRIVATE_V1}/users/password`, payload);
  return response;
});

export const updatePhoneNumber = createAsyncThunk("profile/update/phoneNumber", async (payload) => {
  const response = await putReq(
    `${process.env.REACT_APP_API_PRIVATE_V2}/users/phone_number`,
    payload,
  );
  return response;
});

export const updateEmail = createAsyncThunk("profile/update/email", async (payload) => {
  const response = await putReq(`${process.env.REACT_APP_API_PRIVATE_V1}/users/email`, payload);
  return response;
});

export const updateGender = createAsyncThunk("profile/update/gender", async (payload) => {
  const response = await putReq(`${process.env.REACT_APP_API_PRIVATE_V1}/users/gender`, payload);
  return response;
});

export const updateDOB = createAsyncThunk("profile/update/dateOfBirth", async (payload) => {
  const response = await putReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/users/date_of_birth`,
    payload,
  );
  return response;
});

export const uploadImage = createAsyncThunk("profile/update/image", async (payload) => {
  const response = await putReq(
    `${process.env.REACT_APP_API_PRIVATE_V2}/users/upload_image`,
    payload,
  );
  return response;
});

const profileSlice = createSlice({
  name: "profile",
  initialState: {
    getProfileDetailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updatePasswordObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updatePhoneNumberObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateEmailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateGenderObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateDOBObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    uploadImageObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {
    resetProfile: (state) => {
      state.getProfileDetailObj.data = null;
      state.getProfileDetailObj.status = "idle";
      state.getProfileDetailObj.successMessage = null;
      state.getProfileDetailObj.errorMessage = null;
    },
  },
  extraReducers: {
    [getProfileDetail.pending]: (state) => {
      state.getProfileDetailObj.status = "pending";
    },
    [getProfileDetail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getProfileDetailObj.status = "succeeded";
      state.getProfileDetailObj.data = data;
      state.getProfileDetailObj.successMessage = message;
    },
    [getProfileDetail.rejected]: (state, action) => {
      const { message } = action.error;

      state.getProfileDetailObj.status = "failed";
      state.getProfileDetailObj.errorMessage = message;
    },
    [updatePassword.pending]: (state) => {
      state.updatePasswordObj.status = "pending";
    },
    [updatePassword.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updatePasswordObj.status = "succeeded";
      state.updatePasswordObj.data = data;
      state.updatePasswordObj.successMessage = message;
    },
    [updatePassword.rejected]: (state, action) => {
      const { message } = action.error;

      state.updatePasswordObj.status = "failed";
      state.updatePasswordObj.errorMessage = message;
    },
    [updatePhoneNumber.pending]: (state) => {
      state.updatePhoneNumberObj.status = "pending";
    },
    [updatePhoneNumber.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updatePhoneNumberObj.status = "succeeded";
      state.updatePhoneNumberObj.data = data;
      state.getProfileDetailObj.data = data;
      state.updatePhoneNumberObj.successMessage = message;
    },
    [updatePhoneNumber.rejected]: (state, action) => {
      const { message } = action.error;

      state.updatePhoneNumberObj.status = "failed";
      state.updatePhoneNumberObj.errorMessage = message;
    },
    [updateEmail.pending]: (state) => {
      state.updateEmailObj.status = "pending";
    },
    [updateEmail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateEmailObj.status = "succeeded";
      state.updateEmailObj.data = data;
      state.getProfileDetailObj.data = data;
      state.updateEmailObj.successMessage = message;
    },
    [updateEmail.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateEmailObj.status = "failed";
      state.updateEmailObj.errorMessage = message;
    },
    [updateGender.pending]: (state) => {
      state.updateGenderObj.status = "pending";
    },
    [updateGender.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateGenderObj.status = "succeeded";
      state.updateGenderObj.data = data;
      state.getProfileDetailObj.data = data;
      state.updateGenderObj.successMessage = message;
    },
    [updateGender.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateGenderObj.status = "failed";
      state.updateGenderObj.errorMessage = message;
    },
    [updateDOB.pending]: (state) => {
      state.updateDOBObj.status = "pending";
    },
    [updateDOB.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateDOBObj.status = "succeeded";
      state.updateDOBObj.data = data;
      state.getProfileDetailObj.data = data;
      state.updateDOBObj.successMessage = message;
    },
    [updateDOB.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateDOBObj.status = "failed";
      state.updateDOBObj.errorMessage = message;
    },
    [uploadImage.pending]: (state) => {
      state.uploadImageObj.status = "pending";
    },
    [uploadImage.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.uploadImageObj.status = "succeeded";
      state.uploadImageObj.data = data;
      state.uploadImageObj.successMessage = message;
    },
    [uploadImage.rejected]: (state, action) => {
      const { message } = action.error;

      state.uploadImageObj.status = "failed";
      state.uploadImageObj.errorMessage = message;
    },
  },
});

export default profileSlice.reducer;

// state
export const profileSelector = (state) => state.profile;

export const { resetProfile } = profileSlice.actions;
